import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import {HomeDataContext } from '../../../../contexts/DataProviderHome';
import './TableFlotaStyle.css';

export class TableFlota extends Component {
  static contextType = HomeDataContext;
  handleRowClick = (flotaClic) => {
    const { flota, setFlota } = this.context;
    if (flota=== flotaClic) {
      setFlota("");
    } else setFlota(flotaClic);
  };
  render() {
    const { filteredGeneralData, region , flota} = this.context;

    // Verificar si no hay datos disponibles
    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por flota
    const desembarquePorFlota = {};

    // Calcular el total de desembarque para cada flota
    filteredGeneralData.forEach(item => {
      if (!desembarquePorFlota[item.flota]) {
        desembarquePorFlota[item.flota] = 0;
      }
      desembarquePorFlota[item.flota] += item.desembarque;
    });

    // Calcular el total general de desembarque
    const totalDesembarque = Object.values(desembarquePorFlota).reduce((sum, value) => sum + value, 0);

    // Preparar datos para la tabla y calcular porcentajes
    const data = Object.keys(desembarquePorFlota).map(flota => {
      const desembarque = desembarquePorFlota[flota];
      const percentage = (desembarque / totalDesembarque) * 100;
      return {
        flota,
        desembarque: desembarque.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        percentage: isNaN(percentage) ? '0' : percentage.toFixed(1)
      };
    });

    return (
      <div className='container-table'>
        <TableContainer className='table' component={Paper}>
          <div className='header-table'> {region === '' &  flota === ''? `Desembarque País` : 
region === '' &  flota !== ''? `Desembarque País ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}`: 
region  !== '' & region  !== 'Extranjero' & region  !== 'Lanchas Transportadoras' &   flota === ''? `Desembarque de la Región ${region}`: 
region  !== '' & region  === 'Extranjero' &   flota === ''? `Desembarque en el  ${region}`: 
region  !== '' & region  === 'Lanchas Transportadoras' &   flota === ''? `Desembarque en  ${region}`: 
region  !== '' & region  !== 'Extranjero' & region  !== 'Lanchas Transportadoras' &   flota !== ''? `Desembarque ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} de la Región ${region}`: 
region  !== '' & region  === 'Extranjero' &   flota !== ''? `Desembarque ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}  en el  ${region}`: 
region  !== '' & region  === 'Lanchas Transportadoras' &   flota !== ''? `Desembarque ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}  en  ${region}`: 
region  !== '' &  flota !== ''? `Desembarque ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}  de la Región ${region}`:   `Sin información disponible`
}</div> {/* Mostrar el nombre de la flota */}
          <Table aria-label="flota table" className='table-info'>
            <TableHead>
              <TableRow>
              <TableCell className='text-title-column no-hover-title-column' align="center">Categoria</TableCell>
                <TableCell  className='text-title-column  no-hover-title-column' align="center">Toneladas</TableCell>
                <TableCell className='text-title-column  no-hover-title-column'  align="center">Porcentaje</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                
                <TableRow key={index}
                onClick={() => this.handleRowClick(row.flota)}
                >
               
                  <TableCell component="th" scope="row">
                    {row.flota}
                  </TableCell>
                  <TableCell align="right">{row.desembarque}</TableCell>
                  <TableCell align="right">{row.percentage}%</TableCell>
                </TableRow>
              ))}
              {/* Totales */}
              <TableRow className='table-total no-hover'>
                <TableCell colSpan={1} align="right"><strong className='table-total-text'>Total</strong></TableCell>
                <TableCell align="right" className='table-total-text'>
                  {totalDesembarque.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </TableCell>
                <TableCell align="right" className='table-total-text'>100%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default TableFlota;
