import React, { useContext } from "react";
import { GlobalDataContext } from "../../../contexts/DataProvider";
import { ResourceDataContext } from "../../../contexts/DataProviderResource";
import { Grid, Typography, Paper } from "@mui/material";
import "./ListResourceStyle.css";

const ListResource = () => {
  const { especies } = useContext(GlobalDataContext);
  const { selectedLetter, setEspecie } = useContext(ResourceDataContext);

  const handleLetterClick = (especieclic) => {
    setEspecie(especieclic);

    // Establecer la letra seleccionada en el contexto
  };

  const filteredEspecies = (
    especies?.filter((especie) =>
      especie.especie
        .trim()
        .toUpperCase()
        .startsWith(selectedLetter?.toUpperCase() || "")
    ) || []
  ).sort((a, b) => a.especie.localeCompare(b.especie)); // Ordenar alfabéticamente
  return (
    <Paper className="list-resource-paper" elevation={3}>
      {selectedLetter !== "0" ? (
        selectedLetter && (
          <Typography variant="h6" className="list-resource-title">
            Especies que comienzan con
            <span className="highlighted-letter">{selectedLetter}</span>
          </Typography>
        )
      ) : (
        <></>
      )}
      <Grid className="container-list-resource" container spacing={2}>
        {filteredEspecies.length > 0 || selectedLetter === "0" ? (
          filteredEspecies.map((especie, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                className="species-item"
                onClick={() => handleLetterClick(especie.especie)}
              >
                <Typography variant="body1">
                  {especie.especie.trim()}
                </Typography>
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography className="no-resource-letter" variant="body1" align="center">
              No hay especies que coincidan con la letra seleccionada.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default ListResource;
