import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { RegionDataContext } from '../../../../contexts/DataProviderRegion';
import './TableFlotaRegionsStyle.css';
import { regionNames } from '../../../../helpers/RegionHelpers';
export class TableFlotaRegions extends Component {
  static contextType = RegionDataContext;

  handleRowClick = (flotaClic) => {
    const { flota, setFlota } = this.context;
    if (flota=== flotaClic) {
      setFlota("");
    } else setFlota(flotaClic);
  };

  render() {
    const { filteredGeneralData, region, flota } = this.context;

    // Mapa de las regiones

    // Verificar si no hay datos disponibles
    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Obtener el nombre de la región
    const regionName = regionNames[region] || 'Sin información disponible';

    // Inicializar un objeto para almacenar el desembarque por flota
    const desembarquePorFlota = {};

    // Calcular el total de desembarque para cada flota
    filteredGeneralData.forEach(item => {
      if (!desembarquePorFlota[item.flota]) {
        desembarquePorFlota[item.flota] = 0;
      }
      desembarquePorFlota[item.flota] += item.desembarque;
    });

    // Calcular el total general de desembarque
    const totalDesembarque = Object.values(desembarquePorFlota).reduce((sum, value) => sum + value, 0);

    // Preparar datos para la tabla y calcular porcentajes
    const data = Object.keys(desembarquePorFlota).map(flota => {
      const desembarque = desembarquePorFlota[flota];
      const percentage = (desembarque / totalDesembarque) * 100;
      return {
        flota,
        desembarque: desembarque.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
        percentage: isNaN(percentage) ? '0' : percentage.toFixed(1)
      };
    });

    return (

        <TableContainer className='table-region-flota' component={Paper}>
          <div className='header-table'>
            {region === '' && flota === '' ? `Desembarque País` :
              region === '' && flota !== '' ? `Desembarque País ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}` :
              region !== '' && region !== 999 && region !== 99 && flota === '' ? `Desembarque de la Región ${regionName}` :
              region !== '' && region === 999 && flota === '' ? `Desembarque en el Extranjero` :
              region !== '' && region === 99 && flota === '' ? `Desembarque en Lanchas Transportadoras` :
              region !== '' && region !== 999 && region !== 99 && flota !== '' ? `Desembarque ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} de la Región ${regionName}` :
              region !== '' && region === 999 && flota !== '' ? `Desembarque ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en el Extranjero` :
              region !== '' && region === 99 && flota !== '' ? `Desembarque ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en Lanchas Transportadoras` :
              `Sin información disponible`}
          </div>
          <Table aria-label="flota table" className='table-info-region-flota'>
            <TableHead className="no-hover-title">
              <TableRow className='title-column'>
                <TableCell className='text-title-column' align="center">Categoria</TableCell>
                <TableCell  className='text-title-column' align="center">Toneladas</TableCell>
                <TableCell className='text-title-column'  align="center">Porcentaje</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow 
                key={index}
                onClick={() => this.handleRowClick(row.flota)}
                >
                  <TableCell component="th" scope="row">
                    {row.flota}
                  </TableCell>
                  <TableCell align="right" className='text-data-table'>{row.desembarque}</TableCell>
                  <TableCell align="right">{row.percentage}%</TableCell>
                </TableRow>
              ))}
              {/* Totales */}
              <TableRow className='table-total-region-flota  no-hover'>
                <TableCell colSpan={1} align="right"><strong className='table-total-text'>Total</strong></TableCell>
                <TableCell align="right" className='table-total-text  text-data-table'>
                  {totalDesembarque.toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </TableCell>
                <TableCell align="right" className='table-total-text'>100%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

    );
  }
}

export default TableFlotaRegions;
