import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { FlotaDataContext } from "../../../../contexts/DataProviderFlota";
import "./ChartCircleFlotaCategoriaFlotaEmbStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class ChartCircleFlotaCategoriaFlotaEmb extends Component {
  static contextType = FlotaDataContext;

  getHeaderTitle(region, eslora) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "" && eslora === "")
      return "Embarcaciones Operando por Año, Categoria País";
    if (region === "" && eslora !== "")
      return `Embarcaciones Operando por Año, Categoria ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      }`;
    if (region !== "" && region !== 999 && region !== 99 && eslora === "")
      return `Embarcaciones Operando por Año, Categoria en la Región ${regionName}`;
    if (region !== "" && region === 999 && eslora === "")
      return "Embarcaciones Operando por Año, Categoria en el Extranjero";
    if (region !== "" && region === 99 && eslora === "")
      return "Embarcaciones Operando por Año, Categoria en Lanchas Transportadoras";
    if (region !== "" && region !== 999 && region !== 99 && eslora !== "")
      return `Embarcaciones Operando por Año, Categoria ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en la Región ${regionName}`;
    if (region !== "" && region === 999 && eslora !== "")
      return `Embarcaciones Operando por Año, Categoria ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en el Extranjero`;
    if (region !== "" && region === 99 && eslora !== "")
      return `Embarcaciones Operando por Año, Categoria ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en Lanchas Transportadoras`;

    return "Sin información disponible";
  }

  handleChartClick = (params) => {
    if(params.name===""){
      return
    }else {    const { setEslora, eslora } = this.context;
    let esloraCategoria = params.name.includes("ART.")
      ? params.name.replace("ART.", "").trim()
      : params.name === "RECOLECTOR"
      ? "NO APLICA"
      : params.name;

    if (esloraCategoria !== eslora) {
      setEslora(esloraCategoria);
    } else {
      setEslora("");
    }}

  };

  render() {
    const { filteredFlotaData, region, flota, eslora } = this.context;

    // Verificar si no hay datos disponibles
    if (!filteredFlotaData || filteredFlotaData.length === 0) {
      const optionZeroData = {
        color: ["#9FE080"],
        tooltip: {
          trigger: "item",
          formatter: ":0",
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "Embarcaciones",
            type: "pie",
            radius: "50%",
            data: [{ name: "", value: 0 }],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      return (
        <div className="container-chart-general-flota-resource-emb">
          <div className="title-text-chart-categoria-emb">
            {this.getHeaderTitle(region, flota, eslora)}
          </div>
          <ReactECharts
            className="chart-general-flota-resource-emb"
            option={optionZeroData}
            notMerge={true}
            lazyUpdate={false}
            onEvents={{ click: this.handleChartClick }}
            style={{ height: "88%", width: "100%" }}
          />
        </div>
      );
    }

    const embarcacionesUnicas = {};
    filteredFlotaData.forEach((item) => {
      const clave = `${item.rpa_embarcacion}-${item.categoria_eslora}`;
      if (!embarcacionesUnicas[clave]) {
        embarcacionesUnicas[clave] = {
          rpa_embarcacion: item.rpa_embarcacion,
          eslora: item.categoria_eslora === "NO APLICA"
            ? "RECOLECTOR"
            : item.flota === "ARTESANAL"
              ? `ART. ${item.categoria_eslora}`
              : item.categoria_eslora,
        };
      }
    });

    const conteoEmbarcaciones = {};
    Object.values(embarcacionesUnicas).forEach((item) => {
      const esloraCategoria = item.eslora;
      if (esloraCategoria !== "RECOLECTOR") {
        if (!conteoEmbarcaciones[esloraCategoria]) {
          conteoEmbarcaciones[esloraCategoria] = 0;
        }
        conteoEmbarcaciones[esloraCategoria] += 1;
      } else {
        conteoEmbarcaciones["RECOLECTOR"] = 0; // Mantener el conteo en 0
      }
    });

    const data = Object.keys(conteoEmbarcaciones).map((eslora) => ({
      name: eslora,
      value: conteoEmbarcaciones[eslora],
    })).sort((a, b) => b.value - a.value);

    const option = {
      color: [
        "#9FE080",
        "#5C7BD9",
        "#FFDC60",
        "#28B463",
        "ORANGE",
        "#2980B9",
        "#C70039",
        "#5DADE2",
        "#900C3F",
        "#117864",
        "#581845",
        "#FF5733",
        "#FFC300",
        "#DAF7A6",
        "#1F618D",
        "#2874A6",
        "#D98880",
        "#AF7AC5",
        "#D4AC0D",
        "#1ABC9C",
        "#3498DB",
        "#E74C3C",
        "#2E86C1",
        "#A93226",
        "#F39C12",
        "#C0392B",
        "#27AE60",
        "#F4D03F",
      ],
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          return `${params.name}: ${value} Embarcaciones`;
        },
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Embarcaciones",
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-flota-resource-emb">
        <div className="title-text-chart-categoria-emb">
          {this.getHeaderTitle(region, flota, eslora)}
        </div>
        <ReactECharts
          className="chart-general-flota-resource-emb"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "88%", width: "100%" }}
        />
      </div>
    );
  }
}

export default ChartCircleFlotaCategoriaFlotaEmb;
