import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { ResourceDataContext } from "../../../../contexts/DataProviderResource"; 
import "./ChartCircleCaletaResourceStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class ChartCircleCaletaResource extends Component {
  static contextType = ResourceDataContext;

  getHeaderTitle(region, eslora) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "" && eslora === "")
      return "Desembarque por Caleta País";
    if (region === "" && eslora !== "")
      return `Desembarque por Caleta País ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      }`;

    if (region !== "" && region !== 999 && region !== 99 && eslora === "")
      return `Desembarque por Caleta en la Región ${regionName}`;
    if (region !== "" && region === 999 && eslora === "")
      return "Desembarque por Caleta en el Extranjero";
    if (region !== "" && region === 99 && eslora === "")
      return "Desembarque por Caleta en Lanchas Transportadoras";

    if (region !== "" && region !== 999 && region !== 99 && eslora !== "")
      return `Desembarque por Caleta ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en la Región ${regionName}`;

    if (region !== "" && region === 999 && eslora !== "")
      return `Desembarque por Caleta ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en el Extranjero`;

    if (region !== "" && region === 99 && eslora !== "")
      return `Desembarque por Caleta ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en Lanchas Transportadoras`;

    return "Sin información disponible";
  }

  handleChartClick = (params) => {
    const { setCaleta, caleta } = this.context;

    if (params.name !== caleta) {
      setCaleta(params.name);
    } else {
      setCaleta("");
    }
  };

  render() {
    const { filteredGeneralData, region, flota, eslora } = this.context;
    
    // Verificar si no hay datos disponibles
    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por caleta
    const desembarquePorCaleta = {};

    // Calcular el total de desembarque para cada caleta
    filteredGeneralData.forEach((item) => {
      if (!desembarquePorCaleta[item.caleta]) {
        desembarquePorCaleta[item.caleta] = 0;
      }
      desembarquePorCaleta[item.caleta] += item.desembarque;
    });

    // Preparar datos para el gráfico, ordenados de mayor a menor
    const data = Object.keys(desembarquePorCaleta)
      .map((caleta) => ({
        name: caleta,
        value: desembarquePorCaleta[caleta],
      }))
      .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor

    const option = {
      color: [
        "#9FE080", 
        "#5C7BD9", 
        "#FFDC60", 
        "#28B463",
        "ORANGE",
        "#2980B9",
        "#C70039",
        "#5DADE2",
        "#900C3F",
        "#117864",
        "#581845",
        "#FF5733",
        "#FFC300",
        "#DAF7A6",
        "#1F618D",
        "#2874A6",
        "#D98880",
        "#AF7AC5",
        "#D4AC0D",
        "#1ABC9C",
        "#3498DB",
        "#E74C3C",
        "#2E86C1",
        "#A93226",
        "#F39C12",
        "#C0392B",
        "#27AE60",
        "#F4D03F",
      ],
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(",");
          return `${params.name}: ${formattedValue}`;
        },
      },
      legend: {
        show: false, // Oculta la leyenda
      },
      series: [
        {
          name: "Desembarque",
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-caleta-resource">
        <div className="title-text-chart-caleta-resource">
          {this.getHeaderTitle(region, flota, eslora)}
        </div>

        <ReactECharts
          className="chart-general-caleta-resource"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "85%", width: "100%" }} // Ajusta el tamaño aquí
        />
      </div>
    );
  }
}

export default ChartCircleCaletaResource;
