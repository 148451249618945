import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { RegionDataContext } from "../../../../contexts/DataProviderRegion";
import "./ChartCircleFlotaCategoriaRegionsEmbStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";
export class ChartCircleFlotaCategoriaRegionsEmb extends Component {
  static contextType = RegionDataContext;
  getHeaderTitle(region, eslora) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "" && eslora === "")
      return "Embarcaciones Operando por Año, Categoria País";
    if (region === "" && eslora !== "" )
      return `Embarcaciones Operando por Año, Categoria País ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      }`;

    if (region !== "" && region !== 999 && region !== 99 && eslora === "")
      return `Embarcaciones Operando por Año, Categoria en la Región ${regionName}`;
    if (region !== "" && region === 999 && eslora === "")
      return "Embarcaciones Operando por Año, Categoria en el Extranjero";
    if (region !== "" && region === 99 && eslora === "")
      return "Embarcaciones Operando por Año, Categoria en Lanchas Transportadoras";

    if (region !== "" && region !== 999 && region !== 99 && eslora !== "" )
      return `Embarcaciones Operando por Año, Categoria ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en la Región ${regionName}`;

    if (region !== "" && region === 999 && eslora !== "")
      return `Embarcaciones Operando por Año, Categoria ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en el Extranjero`;



    if (region !== "" && region === 99 && eslora!== "")
      return `Embarcaciones Operando por Año, Categoria ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en Lanchas Transportadoras`;
     
    return "Sin información disponible";
  }


  handleChartClick = (params) => {
    const { setEslora, eslora } = this.context;

    // Reemplaza "ART." si está presente en el nombre
    let esloraCategoria = params.name.includes("ART.")
      ? params.name.replace("ART.", "").trim()
      : params.name === "RECOLECTOR"
      ? "NO APLICA"
      : params.name;

    if (esloraCategoria !== eslora) {
      setEslora(esloraCategoria);
    } else {
      setEslora("");
    }
  };

  render() {
    const { filteredFlotaData, region,  flota, eslora } = this.context;

    // Verificar si no hay datos disponibles
    if (!filteredFlotaData || filteredFlotaData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar las embarcaciones únicas
    const embarcacionesUnicas = {};

    // Agrupar por rpa_embarcacion y eslora
    filteredFlotaData.forEach((item) => {
      // Usar la clave de agrupación única
      const clave = `${item.rpa_embarcacion}-${item.categoria_eslora}`;

      // Agrupar los datos
      if (!embarcacionesUnicas[clave]) {
        embarcacionesUnicas[clave] = {
          rpa_embarcacion: item.rpa_embarcacion,
          eslora:
            item.categoria_eslora === "NO APLICA"
              ? "RECOLECTOR"
              : item.flota === "ARTESANAL"
              ? `ART. ${item.categoria_eslora}`
              : item.categoria_eslora,
        };
      }
    });

    // Inicializar un objeto para contar el número de embarcaciones por categoría de eslora
    const conteoEmbarcaciones = {};

    // Contar las embarcaciones únicas por categoría de eslora, incluyendo "RECOLECTOR"
    Object.values(embarcacionesUnicas).forEach((item) => {
      const esloraCategoria = item.eslora;

      if (esloraCategoria !== "RECOLECTOR") {
        // Solo contar si no es "RECOLECTOR"
        if (!conteoEmbarcaciones[esloraCategoria]) {
          conteoEmbarcaciones[esloraCategoria] = 0; // Inicializar conteo
        }
        conteoEmbarcaciones[esloraCategoria] += 1; // Aumentar el conteo
      } else {
        conteoEmbarcaciones["RECOLECTOR"] = 0; // Mantener el conteo en 0
      }
    });

    // Preparar datos para el gráfico, asegurando que "RECOLECTOR" esté incluido
    const data = Object.keys(conteoEmbarcaciones).map((eslora) => ({
      name: eslora,
      value: conteoEmbarcaciones[eslora], // Usar el conteo de embarcaciones como valor
    })).sort((a, b) => b.value - a.value); // Ordenar de mayor a menor;

    const option = {
      color: ["#9FE080", 
        "#5C7BD9", 
        "#FFDC60", 
        "#28B463", // Verde intenso
        "ORANGE",
        "#2980B9", // Azul intenso
        "#C70039", // Rojo intenso
        "#5DADE2", // Azul cielo fuerte
        "#900C3F", // Vino
        "#117864", // Verde esmeralda
        "#581845", // Púrpura oscuro
        "#FF5733", // Naranja fuerte
        "#FFC300", // Amarillo vibrante
        "#DAF7A6", // Verde claro vibrante
        "#1F618D", // Azul oscuro
        "#2874A6", // Azul mediano
        "#D98880", // Rojo coral
        "#AF7AC5", // Púrpura vibrante
        "#D4AC0D", // Dorado oscuro
        "#1ABC9C", // Verde azulado
        "#3498DB", // Azul medio
        "#E74C3C", // Rojo fuego
        "#2E86C1", // Azul medio-oscuro
        "#A93226", // Rojo oscuro
        "#F39C12", // Naranja vibrante
        "#C0392B", // Rojo granate
        "#27AE60", // Verde profundo
        "#F4D03F",],
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          return `${params.name}: ${value} Embarcaciones`; // Mostrar número de embarcaciones en el tooltip
        },
      },
      legend: {
        show: false, // Oculta la leyenda
      },
      series: [
        {
          name: "Embarcaciones",
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-flota-region-emb">
        <div className="title-text-chart-categoria-emb">
        {this.getHeaderTitle(region, flota, eslora)}
        </div>
        <ReactECharts
          className="chart-general-flota-region-emb"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "88%", width: "100%" }} // Ajusta el tamaño aquí
        />
      </div>
    );
  }
}

export default ChartCircleFlotaCategoriaRegionsEmb;
