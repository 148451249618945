import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { GlobalDataContext } from "../../contexts/DataProvider";
import "./Login.css";
import { AuthService } from "../../services/authService";
import { getTokenData } from '../../helpers/Token.helper';
const Login = (props) => {
  const { showLogin, setShowLogin } = useContext(GlobalDataContext);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const tokenData = getTokenData();
console.log(tokenData);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  const handleLogin = async () => {
    try {
      await AuthService.login(email, password);
      props.setIsAuth(true);
      setShowLogin(false);
      setSnackbarMessage("Inicio de Sesión Exitosa");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (err) {
      setSnackbarMessage("Error al iniciar sesión. Verifica tus credenciales.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCLoseLogin = async () => {
    props.setIsAuth(false);
    setShowLogin(false);
    setEmail("");
    setPassword("");
    setSnackbarMessage("Sesión Cerrada");
    setSnackbarSeverity("out");
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {showLogin ? (
        !props.isAuth ? (
          <Box className="login-container">
            <Box className="login-box">
              <div className="container-login-title">
                <div className="container-login-title-text">
                  <Typography className="title-login" variant="h4" gutterBottom>
                    Iniciar Sesión
                  </Typography>
                </div>
                <IconButton className="close-button" onClick={handleCloseLogin}>
                  <FontAwesomeIcon className="close-button-icon" icon={faTimes} />
                </IconButton>
              </div>

              <div className="container-login-input">
                <TextField
                  label="Correo de usuario"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faUser} />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  label="Contraseña"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faLock} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handlePasswordVisibility} edge="end">
                          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="container-login-button">
                  <Button
                    onClick={handleLogin}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="login-button"
                  >
                    Iniciar Sesión
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
        ) : (
          <Box className="login-container">
            <Box className="login-box">
              <div className="container-login-title">
                <div className="container-login-title-text">
                  <Typography className="title-login" variant="h4" gutterBottom>
                    Sesión Iniciada
                  </Typography>
                </div>
                <IconButton className="close-button" onClick={handleCloseLogin}>
                  <FontAwesomeIcon className="close-button-icon" icon={faTimes} />
                </IconButton>
              </div>

              <div className="container-login-input">
                <h3>Usuario</h3>
                <div className="container-login-button">
                  <Button
                    onClick={handleCLoseLogin}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="login-button"
                  >
                    ¿Quiere Cerrar Sesión?
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
        )
      ) : null}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}  // Cambiado a parte superior
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: "100%",
            fontSize: "1.2rem", // Tamaño de texto aumentado
            color: "white",
            backgroundColor: snackbarSeverity === "success" ? "#4caf50" : "#f44336",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
