import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer } from '@fortawesome/free-solid-svg-icons'; // Ícono que usarás
import './PageUnderConstructionStyle.css'; // Asegúrate de que esta ruta sea correcta

const PageUnderConstruction = () => {
  return (
    <div className='container-page-construction'>
      <Container className='container-page-contrution-2' maxWidth="sm">
        <div className='construction-ico'>
          <FontAwesomeIcon icon={faHammer} />
        </div>
        <Typography variant="h4" component="h1" gutterBottom>
          Página en Construcción
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Estamos trabajando para ofrecerte una mejor experiencia. Vuelve pronto.
        </Typography>
        <Button variant="contained" color="primary" href="/">
          Ir a Inicio
        </Button>
      </Container>
    </div>
  );
};

export default PageUnderConstruction;
