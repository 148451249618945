import React, { useContext } from "react";
import "./FlotaScreenStyle.css";
import FlotaBar from "../../components/FlotaScreenComponent/FlotaBar/FlotaBar";
import Loading from "../../components/Loading/Loading";
import { GlobalDataContext } from "../../contexts/DataProvider";
import { FlotaDataContext } from "../../contexts/DataProviderFlota"; // Asegúrate de que sea FlotaDataContext
import FloatingMenuFlota from "../../components/FlotaScreenComponent/FloatingMenuFlota/FloatingMenuFlota";
import TableFlotaFlota from "../../components/FlotaScreenComponent/TablesFlota/TableFlotaFlota/TableFlotaFlota";
import MapGeneralFlota from "../../components/FlotaScreenComponent/MapGeneralFlota/MapGeneralFlota";
import ChartCircleCaletaFlota from "../../components/FlotaScreenComponent/ChartsFlota/ChartCircleCaletaFlota/ChartCircleCaletaFlota";
import ChartCircleFlotaCategoriaFlotaDesemb from "../../components/FlotaScreenComponent/ChartsFlota/ChartCircleFlotaCategoriaFlotaDesemb/ChartCircleFlotaCategoriaFlotaDesemb";
import ChartCircleFlotaCategoriaFlotaEmb from "../../components/FlotaScreenComponent/ChartsFlota/ChartCircleFlotaCategoriaFlotaEmb/ChartCircleFlotaCategoriaFlotaEmb";
import ChartCircleEspecieFlota from "../../components/FlotaScreenComponent/ChartsFlota/ChartCircleEspecieFlota/ChartCircleEspecieFlota";
import ChartCircleRegionFlota from "../../components/FlotaScreenComponent/ChartsFlota/ChartCircleRegionFlota/ChartCircleRegionFlota";
import ChartLinePescadoresCategoriasFlota from "../../components/FlotaScreenComponent/ChartsFlota/ChartLinePescadoresCategoriasFlota/ChartLinePescadoresCategoriasFlota";
import ChartLineYearFlota from "../../components/FlotaScreenComponent/ChartsFlota/ChartLineYearFlota/ChartLineYearFlota";
import TableFlotaEmbarcacionesFlota from "../../components/FlotaScreenComponent/TablesFlota/TableFlotaEmbarcacionesFlota/TableFlotaEmbarcacionesFlota";
import TableFlotaFlotaCaleta from "../../components/FlotaScreenComponent/TablesFlota/TableFlotaFlotaCaleta/TableFlotaFlotaCaleta";
import TableFlotaFlotaEspecie from "../../components/FlotaScreenComponent/TablesFlota/TableFlotaFlotaEspecie/TableFlotaFlotaEspecie";
import TableFlotaFlotaYear from "../../components/FlotaScreenComponent/TablesFlota/TableFlotaFlotaYear/TableFlotaFlotaYear";
import TablePescadoresCategoriaAgrupadasFlota from "../../components/FlotaScreenComponent/TablesFlota/TablePescadoresCategoriaAgrupadasFlota/TablePescadoresCategoriaAgrupadasFlota";
import TablePescadoresFlota from "../../components/FlotaScreenComponent/TablesFlota/TablePescadoresFlota/TablePescadoresFlota";
import TableFlotaFlotaRegion from "../../components/FlotaScreenComponent/TablesFlota/TableFlotaFlotaRegion/TableFlotaFlotaRegion";
import ChartLineRegionFlota from "../../components/FlotaScreenComponent/ChartsFlota/ChartLineRegionFlota/ChartLineRegionFlota";
export default function FlotaScreen() {
  const { isLoader } = useContext(GlobalDataContext);
  const { flota } = useContext(FlotaDataContext); // Asegúrate de que region esté definido en FlotaDataContext

  return (
    <div className="container-flota-select-screens">
      {isLoader ? (
        <Loading />
      ) : (
        <div className="container-flota-select-0">
          {flota=== "" ? (
            <>
              <FlotaBar />
           
            </>
          ) : (
            <>
            <FloatingMenuFlota  />

            <div className="container-flota-info">
              <div className="container-flota-1">
              <MapGeneralFlota />

              <div>
              <TableFlotaFlota />
              <ChartLineRegionFlota/>
              </div>
        


              </div>

              <div className="container-flota-7">
                <ChartCircleRegionFlota/>
                <TableFlotaFlotaRegion />
              </div>
              <div className="container-flota-1">
                <TableFlotaFlotaCaleta />
                <ChartCircleCaletaFlota />
              </div>
              <div className="container-flota-1">
                <TablePescadoresFlota />
                <TablePescadoresCategoriaAgrupadasFlota />
              </div>
              <div className="container-flota-1">
                <ChartLinePescadoresCategoriasFlota />
              </div>
              <div className="container-flota-1"></div>
              <div className="container-flota-2">
                <ChartLineYearFlota />
                <TableFlotaFlotaYear />
              </div>
              <div className="container-flota-3">

              </div>
              <div className="container-flota-4">
                <ChartCircleEspecieFlota />
                <TableFlotaFlotaEspecie  />
              </div>

              <div className="container-flota-5">
                <TableFlotaEmbarcacionesFlota />
              </div>
              <div className="container-flota-6">
                <ChartCircleFlotaCategoriaFlotaEmb />
                <ChartCircleFlotaCategoriaFlotaDesemb />
              </div>
       
            </div>
          </>
          )}
        </div>
      )}
    </div>
  );
}
