import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FlotaDataContext } from "../../../../contexts/DataProviderFlota"; 
import "./TableFlotaFlotaCaletaStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class TableFlotaFlotaCaleta extends Component {
  static contextType = FlotaDataContext;

  handleRowClick = (caletaClic) => {
    const { caleta, setCaleta } = this.context;
    if (caleta === caletaClic) {
      setCaleta("");
    } else setCaleta(caletaClic);
  };

  render() {
    const { filteredGeneralData, region, flota, caleta } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const regionName = regionNames[region] || "Sin información disponible";

    // Agrupamos datos por región y caleta
    const desembarquePorCaleta = {};
    filteredGeneralData.forEach((item) => {
      const key = `${item.region_desembarque}-${item.caleta}`; // Clave única por región y caleta
      if (!desembarquePorCaleta[key]) {
        desembarquePorCaleta[key] = {
          region: item.region_desembarque,
          caleta: item.caleta,
          desembarque: 0,
        };
      }
      desembarquePorCaleta[key].desembarque += item.desembarque;
    });

    const totalDesembarque = Object.values(desembarquePorCaleta).reduce(
      (sum, value) => sum + value.desembarque,
      0
    );

    // Función para determinar el número de decimales basados en el valor
    const getDecimalPlaces = (type, value) => {
      if (value >= 1 && type === 'desembarque') return 0;
      if (value >= 1 && type === 'porcentaje') return 1;
      const magnitude = Math.abs(Math.log10(value));
      return Math.min(6, Math.ceil(magnitude));
    };

    // Formateo de datos con porcentaje y toneladas
    const data = Object.values(desembarquePorCaleta).map((item) => {
      const percentage = (item.desembarque / totalDesembarque) * 100;
      
      const decimalDesembarque = getDecimalPlaces('desembarque', item.desembarque);
      const decimalPercentage = getDecimalPlaces('porcentaje', percentage);

      return {
        region: item.region,
        caleta: item.caleta,
        desembarque: item.desembarque,
        formattedDesembarque: item.desembarque.toLocaleString("de-DE", {
          minimumFractionDigits: decimalDesembarque,
          maximumFractionDigits: decimalDesembarque,
        }),
        percentage: isNaN(percentage)
          ? "0"
          : percentage.toLocaleString("de-DE", {
              minimumFractionDigits: decimalPercentage,
              maximumFractionDigits: decimalPercentage,
            }),
      };
    });

    // Ordenamos los datos por el desembarque de mayor a menor
    data.sort((a, b) => b.desembarque - a.desembarque);

    return (
      <TableContainer className="table-region-caleta" component={Paper}>
        <div className={caleta === '' ? "header-table-region-caleta" : "header-table-region-caleta-select"}>
          {/* Título dinámico */}
          <div>
            {region === "" && flota === ""
              ? `Desembarque por caleta País`
              : region === "" && flota !== ""
              ? `Desembarque por caleta País ${
                  flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                }`
              : region !== "" &&
                region !== 999 &&
                region !== 99 &&
                flota === ""
              ? `Desembarque por caleta de la Región ${regionName}`
              : region !== "" && region === 999 && flota === ""
              ? `Desembarque por caleta en el Extranjero`
              : region !== "" && region === 99 && flota === ""
              ? `Desembarque por caleta en Lanchas Transportadoras`
              : region !== "" &&
                region !== 999 &&
                region !== 99 &&
                flota !== ""
              ? `Desembarque por caleta ${
                  flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                } de la Región ${regionName}`
              : region !== "" && region === 999 && flota !== ""
              ? `Desembarque por caleta ${
                  flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                } en el Extranjero`
              : region !== "" && region === 99 && flota !== ""
              ? `Desembarque por caleta ${
                  flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                } en Lanchas Transportadoras`
              : `Sin información disponible`}
          </div>
        </div>
        <Table aria-label="flota table" className="table-info-region-caleta">
          <TableHead className="no-hover-title">
            <TableRow className="title-column">
              <TableCell className="text-title-column" align="left">Región</TableCell>
              <TableCell className="text-title-column" align="left">Caleta/Puerto</TableCell>
              <TableCell className="text-title-column" align="center">Toneladas</TableCell>
              <TableCell className="text-title-column" align="center">Porcentaje</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                onClick={() => this.handleRowClick(row.caleta)}
              >
                <TableCell align="left">{regionNames[row.region] || "Sin información disponible"}</TableCell>
                <TableCell align="left">{row.caleta}</TableCell>
                <TableCell align="right" className="text-data-table">{row.formattedDesembarque}</TableCell>
                <TableCell align="right">{row.percentage}%</TableCell>
              </TableRow>
            ))}
            <TableRow className="table-total-region-caleta no-hover">
              <TableCell colSpan={2} align="right">
                <strong className="table-total-text-region-caleta">Total</strong>
              </TableCell>
              <TableCell
                align="right"
                className="table-total-text-region-caleta text-data-table"
              >
                {totalDesembarque.toLocaleString("de-DE", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </TableCell>
              <TableCell align="right" className="table-total-text-region-caleta">
                100%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default TableFlotaFlotaCaleta;
