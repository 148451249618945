import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { RegionDataContext } from "../../../../contexts/DataProviderRegion";
import { isMobile } from "react-device-detect";
import "./ChartLineYearRegionStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class ChartLineYearRegion extends Component {
  static contextType = RegionDataContext;

  getHeaderTitle(region) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "")
      return "Desembarque por Año, País";
    if (region !== "" && region !== 999 && region !== 99)
      return `Desembarque por Año en la Región ${regionName}`;
    if (region === 999)
      return "Desembarque por Año en el Extranjero";
    if (region === 99)
      return "Desembarque por Año en Lanchas Transportadoras";

    return "Sin información disponible";
  }

  render() {
    const { filteredGeneralData, region } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const desembarquesPorAno = {};
    const yearsSet = new Set();

    filteredGeneralData.forEach((item) => {
      if (!desembarquesPorAno[item.year]) {
        desembarquesPorAno[item.year] = 0;
      }

      // Sumar desembarques sin tener en cuenta las flotas
      desembarquesPorAno[item.year] += item.desembarque;
      yearsSet.add(item.year);
    });

    const years = Array.from(yearsSet).sort();

    // Crear los datos de la serie, ahora solo hay una línea (sin flotas)
    const seriesData = {
      type: "line",
      data: years.map((year) => desembarquesPorAno[year] || 0),
    };

    const formatChileanNumber = (value) => {
      if (isNaN(value)) return 0;
      const roundedValue = Math.round(value * 10) / 10;
      const parts = roundedValue.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    };

    const option = {
      color: ["#5C7BD9"],
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          let tooltipText = `${params[0].name}<br/>`;
          let total = 0;

          params.forEach((param) => {
            tooltipText += `${param.marker} ${param.seriesName}: ${formatChileanNumber(param.value)}<br/>`;
            total += param.value;
          });

          if (!isNaN(total)) {
            tooltipText += `<strong>Total: ${formatChileanNumber(total)}</strong>`;
          } else {
            console.error("Total no válido en tooltip:", total);
          }

          return tooltipText;
        },
      },
      legend: {
        data: ["Desembarques"],
      },
      xAxis: {
        type: "category",
        axisLabel: {
          rotate: isMobile ? 90 : 0,
        },
        data: years,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: formatChileanNumber,
        },
      },
      series: [seriesData], // Ahora solo hay una serie
    };

    return (
      <div className="container-chart-general-line-region-year">
        <div className="title-text-chart-year-year">
          {this.getHeaderTitle(region)}
        </div>
        <ReactECharts
          className="chart-general-region-year"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          style={{ height: "87%", width: "100%", minHeight: "300px" }}
        />
      </div>
    );
  }
}

export default ChartLineYearRegion;
