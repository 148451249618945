import React, { useContext } from "react";
import {  Drawer } from "@mui/material";
import ListMenu from "../../helpers/MenuHelpers"; // Importamos la lista
import { GlobalDataContext  } from "../../contexts/DataProvider";
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../assets/img/FISH2.png';
import './DrawerMenuStyle.css';

const DrawerMenu = () => {
  const { showDrawer, setShowDrawer } = useContext(GlobalDataContext);
  const navigate = useNavigate();


  const goTo = (view) => {
    navigate(`/${view}`);
    setShowDrawer(false);
  };


  const closeDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <div >
      <Drawer className='continer-drawer' open={showDrawer}  onClose={closeDrawer}>
      <div className='container-header-drawer'>
          <div className='container-title-drawer' >
            <img
              className='link-logo-drawer'
              src={logo}
              alt="Logo de empresa SmartFish"
            />
            <h1 className='text-logo-drawer'>SmartFish</h1>
          </div>
          <h4 onClick={closeDrawer} className='close'>X</h4>
        </div>
        {ListMenu.map((element, index) => (
           <div className='container-item-menu'key={index} onClick={() => goTo(element.path)}>
             <FontAwesomeIcon  className='logo-item-menu' icon={ element.icon} />
            <h3 className="text-item-menu">{element.text}</h3>
          </div>
        ))}
      </Drawer>
    </div>
  );
};

export default DrawerMenu;

