import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { ResourceDataContext } from "../../../../contexts/DataProviderResource"; 
import { isMobile } from "react-device-detect";
import "./ChartLineFlotaResourceStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class ChartLineFlotaResource extends Component {
  static contextType = ResourceDataContext;

  handleFlotaChange = (selectedFlota) => {
    // Actualiza la flota en el contexto
    if (this.context.flota === selectedFlota) {
      this.context.setFlota("");
    } else this.context.setFlota(selectedFlota);
  };

  getHeaderTitle(region, flota) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "" && flota === "")
      return "Desembarque por Año, Flota País";
    if (region === "" && flota !== "" && flota !== "RECOLECTOR")
      return `Desembarque por Año, Flota País ${
        flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
      }`;
      if (region === ""  && flota === "RECOLECTOR")
        return `Desembarque por Año, País ${
          flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
        }`;
    if (region !== "" && region !== 999 && region !== 99 && flota === "")
      return `Desembarque por Año, Flota en la Región ${regionName}`;
    if (region !== "" && region === 999 && flota === "")
      return "Desembarque por Año, Flota en el Extranjero";
    if (region !== "" && region === 99 && flota === "")
      return "Desembarque por Año, Flota en Lanchas Transportadoras";

    if (region !== "" && region !== 999 && region !== 99 && flota !== "" && flota !== "RECOLECTOR")
      return `Desembarque por Año, Flota ${
        flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
      } en la Región ${regionName}`;

      if (region !== "" && region !== 999 && region !== 99 && flota === "RECOLECTOR")
        return `Desembarque por Año, ${
          flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
        } en la Región ${regionName}`;
      
    if (region !== "" && region === 999 && flota !== ""&& flota !== "RECOLECTOR")
      return `Desembarque por Año, Flota ${
        flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
      } en el Extranjero`;

      if (region !== "" && region === 999 && flota === "RECOLECTOR")
        return `Desembarque por Año, ${
          flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
        } en el Extranjero`;

    if (region !== "" && region === 99 && flota !== ""&& flota !== "RECOLECTOR")
      return `Desembarque por Año, Flota ${
        flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
      } en Lanchas Transportadoras`;
      if (region !== "" && region === 99 && flota === "RECOLECTOR")
        return `Desembarque por Año, ${
          flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
        } en Lanchas Transportadoras`;  
    return "Sin información disponible";
  }

  render() {
    const { filteredGeneralData, region, flota } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const flotas = {};
    const yearsSet = new Set(); // Set para almacenar años únicos

    filteredGeneralData.forEach((item) => {
      if (!flotas[item.flota]) {
        flotas[item.flota] = {};
      }
      if (!flotas[item.flota][item.year]) {
        flotas[item.flota][item.year] = 0;
      }

      // Suma de desembarques
      flotas[item.flota][item.year] += item.desembarque;
      yearsSet.add(item.year); // Añade el año al Set de años únicos
    });

    const years = Array.from(yearsSet).sort(); // Convierte el Set en Array y lo ordena

    const seriesData = Object.keys(flotas).map((flota) => ({
      name: flota,
      type: "line",
      data: years.map((year) => flotas[flota][year] || 0), // Asegura que haya datos para todos los años, si no, 0
    }));

    const formatChileanNumber = (value) => {
      if (isNaN(value)) return 0;
      const roundedValue = Math.round(value * 10) / 10;
      const parts = roundedValue.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(",");
    };

    const option = {
      color: ["#9FE080", "#5C7BD9", "#FFDC60"],
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          let tooltipText = `${params[0].name}<br/>`;
          let total = 0;

          params.forEach((param) => {
            tooltipText += `${param.marker} ${
              param.seriesName
            }: ${formatChileanNumber(param.value)}<br/>`;
            total += param.value;
          });

          // Validación para evitar errores en la suma total
          if (!isNaN(total)) {
            tooltipText += `<strong>Total: ${formatChileanNumber(
              total
            )}</strong>`;
          } else {
            console.error("Total no válido en tooltip:", total);
          }

          return tooltipText;
        },
      },
      legend: {
        data: Object.keys(flotas),
      },
      xAxis: {
        type: "category",
        axisLabel: {
          rotate: isMobile ? 90 : 0,
        },
        data: years, // Usar los años únicos
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: formatChileanNumber,
        },
      },
      series: seriesData,
    };

    return (
      <div className="container-chart-general-line-resource">
        <div className="title-text-chart-year-resource">
          {this.getHeaderTitle(region, flota)}
        </div>
        <ReactECharts
          className="chart-general-resource"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          style={{ height: "87%", width: "100%", minHeight: "300px" }}
          onEvents={{
            legendselectchanged: (params) => {
              this.handleFlotaChange(params.name); // Actualiza la flota seleccionada
            },
          }}
        />
      </div>
    );
  }
}

export default ChartLineFlotaResource;
