// DataProvider.js
import React, { useState, useEffect } from 'react';

export const GlobalDataContext = React.createContext();

const DataProvider = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [especies, setEspecies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoader(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/recursos/recursos`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setEspecies(jsonData); // Esto es seguro, ya que no depende de especies
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setIsLoader(false);
    };
  
    fetchData();
  }, []); // Se ejecuta solo al montar el componente
  

  return (
    <GlobalDataContext.Provider
      value={{
        showDrawer,
        setShowDrawer,
        isLoader,
        setIsLoader,
        showLogin,
        setShowLogin,
        isAuth,
        setIsAuth,
        especies,
        setEspecies,
      }}
    >
      {props.children}
    </GlobalDataContext.Provider>
  );
};

export default DataProvider;
