import React, { useContext } from "react";
import "./AnalisisIAStyle.css"; // Importa tu archivo de estilos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { RegionDataContext } from "../../../src/contexts/DataProviderRegion";

const AnalisisIA = () => {
  const { isVisibleIA, setIsVisibleIA, responseIA, setResponseIA } = useContext(RegionDataContext);

  const togglePopup = () => {
    setResponseIA('')
    setIsVisibleIA(false); // Cierra el popup al hacer clic en "Cerrar"
  };

  // Formatear la respuesta de la IA
  const formatResponse = (response) => {
    // Divide la respuesta en líneas o párrafos
    const paragraphs = response.split('\n');
    return paragraphs.map((paragraph, index) => {
      // Manejo para cada párrafo, se puede ajustar según el formato esperado
      if (paragraph.trim() === '') return null; // Ignorar líneas vacías
      return (
        <p key={index}>
          {paragraph}
        </p>
      );
    });
  };

  return (
    <>
      {isVisibleIA && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Análisis IA</h2>
            <FontAwesomeIcon className="Brain-text-ia" icon={faBrain} />
            <div className="response-ia">
              {responseIA ? formatResponse(responseIA) : "Cargando análisis..."}
            </div>
            <button onClick={togglePopup} className="close-btn">
              Cerrar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AnalisisIA;
