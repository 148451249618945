import React, { useContext, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { RegionDataContext } from "../../../../contexts/DataProviderRegion";
import "./TablePescadoresCategoriaAgrupadasRegionStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

const TablePescadoresCategoriaAgrupadasRegion = () => {
  const { filteredFishermanData, region, categoria_pescador } = useContext(RegionDataContext);
  const regionName = regionNames[region] || "Sin información disponible";

  const handleRowClick = (pescadorClic) => {
    /* lógica para el clic */
  };

  const getHeaderTitle = (region, categoria_pescador, regionName) => {
    if (region === "" && categoria_pescador === "")
      return "Operación de Pescadores en más de una Categoría País";
    if (region === "" && categoria_pescador !== "")
      return `Operación de Pescadores en más de una Categoría ${categoria_pescador}`;
    if (
      region !== "" &&
      region !== 999 &&
      region !== 99 &&
      categoria_pescador === ""
    )
      return `Operación de Pescadores en más de una Categoría en la Región ${regionName}`;
    if (region !== "" && region === 999 && categoria_pescador === "")
      return "Operación de Pescadores en más de una Categoría en el Extranjero";
    if (region !== "" && region === 99 && categoria_pescador === "")
      return "Operación de Pescadores por en más de una Categoría en Lanchas Transportadoras";
    if (
      region !== "" &&
      region !== 999 &&
      region !== 99 &&
      categoria_pescador !== ""
    )
      return `Operación de Pescadores en más de una Categoría en la Región ${regionName}`;
    return "Sin información disponible";
  };

  const calculateTotals = (filteredFishermanData) => {
    const conteoPorPescador = {};

    // Agrupar por rpa_pescador y contar las categorías de pescador
    filteredFishermanData.forEach((item) => {
      const rpaKey = item.rpa_pescador;
      const flotaKey = item.flota2; // Obtener flota2

      // Si el rpa_pescador aún no ha sido procesado, inicializar su conteo
      if (!conteoPorPescador[rpaKey]) {
        conteoPorPescador[rpaKey] = {
          rpa_pescador: rpaKey,
          flota2: new Set(), // Usar un Set para asegurar que se almacenen las flotas únicas
          armador_count: 0,
          recolector_count: 0,
          buzo_count: 0,
        };
      }

      // Agregar la flota al conjunto
      conteoPorPescador[rpaKey].flota2.add(flotaKey);

      // Contar las categorías por rpa_pescador
      if (item.categoria_pescador === "ARMADOR") {
        conteoPorPescador[rpaKey].armador_count += 1;
      } else if (item.categoria_pescador === "RECOLECTOR") {
        conteoPorPescador[rpaKey].recolector_count += 1;
      } else if (item.categoria_pescador === "BUZO") {
        conteoPorPescador[rpaKey].buzo_count += 1;
      }
    });

    // Determinar la categoría combinada para cada rpa_pescador
    const conteoPorCategoriaAgrupada = {};
    Object.values(conteoPorPescador).forEach((pescador) => {
      const flotas = Array.from(pescador.flota2); // Convertir el Set a un array para iterar
      flotas.forEach((flotaKey) => {
        let categoriaAgrupada = "SIN CATEGORIA PESCADOR";

        // Determinación de la categoría agrupada
        if (
          pescador.armador_count === 0 &&
          pescador.recolector_count === 0 &&
          pescador.buzo_count >= 1
        ) {
          categoriaAgrupada = "BUZO";
        } else if (
          pescador.armador_count === 0 &&
          pescador.recolector_count >= 1 &&
          pescador.buzo_count === 0
        ) {
          categoriaAgrupada = "RECOLECTOR";
        } else if (
          pescador.armador_count === 0 &&
          pescador.recolector_count >= 1 &&
          pescador.buzo_count >= 1
        ) {
          categoriaAgrupada = "BUZO-RECOLECTOR";
        } else if (
          pescador.armador_count >= 1 &&
          pescador.recolector_count === 0 &&
          pescador.buzo_count === 0
        ) {
          categoriaAgrupada = "ARMADOR";
        } else if (
          pescador.armador_count >= 1 &&
          pescador.recolector_count === 0 &&
          pescador.buzo_count >= 1
        ) {
          categoriaAgrupada = "ARMADOR-BUZO";
        } else if (
          pescador.armador_count >= 1 &&
          pescador.recolector_count >= 1 &&
          pescador.buzo_count === 0
        ) {
          categoriaAgrupada = "ARMADOR-RECOLECTOR";
        } else if (
          pescador.armador_count >= 1 &&
          pescador.recolector_count >= 1 &&
          pescador.buzo_count >= 1
        ) {
          categoriaAgrupada = "ARMADOR-BUZO-RECOLECTOR";
        }

        if (!conteoPorCategoriaAgrupada[categoriaAgrupada]) {
          conteoPorCategoriaAgrupada[categoriaAgrupada] = {};
        }

        if (!conteoPorCategoriaAgrupada[categoriaAgrupada][flotaKey]) {
          conteoPorCategoriaAgrupada[categoriaAgrupada][flotaKey] = 0;
        }

        // Incrementar el conteo para la flota y categoría
        conteoPorCategoriaAgrupada[categoriaAgrupada][flotaKey] += 1;
      });
    });

    // Formatear los resultados en un array para la tabla
    const data = [];
    Object.keys(conteoPorCategoriaAgrupada).forEach((categoria) => {
      Object.keys(conteoPorCategoriaAgrupada[categoria]).forEach((flota) => {
        data.push({
          flota,
          categoria_pescador: categoria,
          conteopescadores: conteoPorCategoriaAgrupada[categoria][flota],
        });
      });
    });

    // Ordenar los datos por flota y luego por categoría de pescador
    data.sort((a, b) => {
      // Ordenar por flota primero
      const flotaComparison = b.flota.localeCompare(a.flota);
      if (flotaComparison !== 0) return flotaComparison;

      // Si las flotas son iguales, ordenar por longitud de la categoría de pescador
      return a.categoria_pescador.length - b.categoria_pescador.length;
    });

    // Calcular el total de pescadores
    const totalPescadores = data.reduce((acc, row) => acc + row.conteopescadores, 0);

    return { data, totalPescadores }; // Agregamos el totalPescadores al retorno
  };

  const { data, totalPescadores } = useMemo(() => calculateTotals(filteredFishermanData), [filteredFishermanData]);

  if (!filteredFishermanData || filteredFishermanData.length === 0) {
    return (
      <div className="container-table-region-pescador">
        <TableContainer className="table-region-pescador" component={Paper}>
          <div className="header-table-region-pescador">
            <div className="header-table">
              {getHeaderTitle(region, categoria_pescador, regionName)}
            </div>
          </div>
          <Table aria-label="pescador table" className="table-info-region-pescador">
            <TableHead>
              <TableRow className="title-column">
                <TableCell className="text-title-column" align="left">Información de los pescadores</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center">No hay datos disponibles</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <div className="container-table-region-pescador">
      <TableContainer className="table-region-pescador" component={Paper}>
        <div className="header-table-region-pescador">
          <div className="header-table">
            {getHeaderTitle(region, categoria_pescador, regionName)}
          </div>
        </div>
        <Table aria-label="pescador table" className="table-info-region-pescador">
          <TableHead>
            <TableRow className="title-column">
              <TableCell className="text-title-column" align="left">Flota</TableCell>
              <TableCell className="text-title-column" align="left">Categoría de Pescador</TableCell>
              <TableCell className="text-title-column" align="left">Conteo Pescadores</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.flota + row.categoria_pescador} onClick={() => handleRowClick(row)}>
                <TableCell align="left">{row.flota}</TableCell>
                <TableCell align="left">{row.categoria_pescador}</TableCell>
                <TableCell align="left">{row.conteopescadores}</TableCell>
              </TableRow>
            ))}
<TableRow className="table-total-region-year no-hover">
  <TableCell align="right" colSpan={2}>
    <strong className="table-total-text-region-year">
      Total
    </strong>
  </TableCell>
  <TableCell
    align="right"
    className="table-total-text-region-year text-data-table"
  >
    {totalPescadores.toLocaleString("de-DE", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </TableCell>
</TableRow>

          </TableBody>
        </Table>

      </TableContainer>
    </div>
  );
};

export default TablePescadoresCategoriaAgrupadasRegion;
