import React, { useContext } from 'react';
import './AlphabetBarStyle.css';
import { ResourceDataContext } from '../../../contexts/DataProviderResource';

const AlphabetBar = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const { setSelectedLetter } = useContext(ResourceDataContext); 

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter); // Establecer la letra seleccionada en el contexto
  };

  return (
    <div className="alphabet-bar">
      {alphabet.map((letter) => (
        <button 
          key={letter} 
          className="alphabet-button" 
          onClick={() => handleLetterClick(letter)} // Llamar a handleLetterClick al hacer clic
        >
          {letter}
        </button>
      ))}
    </div>
  );
};

export default AlphabetBar;
