import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { RegionDataContext } from "../../../../contexts/DataProviderRegion";
import "./ChartCircleEspeciesRegionsStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";
export class ChartCircleEspeciesRegions extends Component {
  static contextType = RegionDataContext;
  getHeaderTitle(region, eslora) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "" && eslora === "")
      return "Desembarque por Especie País";
    if (region === "" && eslora !== "" )
      return `Desembarque por Especie País ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      }`;

    if (region !== "" && region !== 999 && region !== 99 && eslora === "")
      return `Desembarque por Especie en la Región ${regionName}`;
    if (region !== "" && region === 999 && eslora === "")
      return "Desembarque por Especie en el Extranjero";
    if (region !== "" && region === 99 && eslora === "")
      return "Desembarque por Especie en Lanchas Transportadoras";

    if (region !== "" && region !== 999 && region !== 99 && eslora !== "" )
      return `Desembarque por Especie ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en la Región ${regionName}`;

    if (region !== "" && region === 999 && eslora !== "")
      return `Desembarque por Especie ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en el Extranjero`;



    if (region !== "" && region === 99 && eslora!== "")
      return `Desembarque por Especie ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en Lanchas Transportadoras`;
     
    return "Sin información disponible";
  }
  handleChartClick = (params) => {
    const { setEspecie, especie } = this.context;

    if (params.name !== especie) {
      setEspecie(params.name);
    } else {
      setEspecie("");
    }
  };

  render() {
    const { filteredGeneralData,region,  flota, eslora } = this.context;
    // Verificar si no hay datos disponibles
    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por especie
    const desembarquePorespecie = {};

    // Calcular el total de desembarque para cada especie
    filteredGeneralData.forEach((item) => {
      if (!desembarquePorespecie[item.especie]) {
        desembarquePorespecie[item.especie] = 0;
      }
      desembarquePorespecie[item.especie] += item.desembarque;
    });

// Preparar datos para el gráfico, ordenados de mayor a menor
const data = Object.keys(desembarquePorespecie)
  .map((especie) => ({
    name: especie,
    value: desembarquePorespecie[especie],
  }))
  .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor


    const option = {
      color: [
        "#9FE080", 
        "#5C7BD9", 
        "#FFDC60", 
        "#28B463", // Verde intenso
        "ORANGE",
        "#2980B9", // Azul intenso
        "#C70039", // Rojo intenso
        "#5DADE2", // Azul cielo fuerte
        "#900C3F", // Vino
        "#117864", // Verde esmeralda
        "#581845", // Púrpura oscuro
        "#FF5733", // Naranja fuerte
        "#FFC300", // Amarillo vibrante
        "#DAF7A6", // Verde claro vibrante
        "#1F618D", // Azul oscuro
        "#2874A6", // Azul mediano
        "#D98880", // Rojo coral
        "#AF7AC5", // Púrpura vibrante
        "#D4AC0D", // Dorado oscuro
        "#1ABC9C", // Verde azulado
        "#3498DB", // Azul medio
        "#E74C3C", // Rojo fuego
        "#2E86C1", // Azul medio-oscuro
        "#A93226", // Rojo oscuro
        "#F39C12", // Naranja vibrante
        "#C0392B", // Rojo granate
        "#27AE60", // Verde profundo
        "#F4D03F", // Amarillo fuerte
        ],

      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(",");
          return `${params.name}: ${formattedValue}`;
        },
      },
      legend: {
        show: false, // Oculta la leyenda
      },
      series: [
        {
          name: "Desembarque",
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-especie-region">
        <div className="title-text-chart-especie">
        {this.getHeaderTitle(region, flota, eslora)}
        </div>

        <ReactECharts
          className="chart-general-especie-region"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "85%", width: "100%" }} // Ajusta el tamaño aquí
        />
      </div>
    );
  }
}

export default ChartCircleEspeciesRegions;
