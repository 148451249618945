import React, { Component } from 'react';
import ReactECharts from 'echarts-for-react';
import { ResourceDataContext } from "../../../../contexts/DataProviderResource"; 
import './ChartCircleResourceFlotaStyle.css';

export class ChartCircleResourceFlota extends Component {
  static contextType = ResourceDataContext;

  handleChartClick = (params) => {
    const { setFlota, flota } = this.context;

    if (params.name !== flota) {
      setFlota(params.name);
    } else {
      setFlota('');
    }
  };

  render() {
    const { filteredGeneralData } = this.context;
    // Verificar si no hay datos disponibles
    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por flota
    const desembarquePorFlota = {};

    // Calcular el total de desembarque para cada flota
    filteredGeneralData.forEach(item => {
      if (!desembarquePorFlota[item.flota]) {
        desembarquePorFlota[item.flota] = 0;
      }
      desembarquePorFlota[item.flota] += item.desembarque;
    });

    // Preparar datos para el gráfico
    const data = Object.keys(desembarquePorFlota).map(flota => ({
      name: flota,
      value: desembarquePorFlota[flota]
    })).sort((a, b) => b.value - a.value);

    const option = {
      color: ["#9FE080", 
        "#5C7BD9", 
        "#FFDC60", 
        "#28B463", // Verde intenso
        "ORANGE",
        "#2980B9", // Azul intenso
        "#C70039", // Rojo intenso
        "#5DADE2", // Azul cielo fuerte
        "#900C3F", // Vino
        "#117864", // Verde esmeralda
        "#581845", // Púrpura oscuro
        "#FF5733", // Naranja fuerte
        "#FFC300", // Amarillo vibrante
        "#DAF7A6", // Verde claro vibrante
        "#1F618D", // Azul oscuro
        "#2874A6", // Azul mediano
        "#D98880", // Rojo coral
        "#AF7AC5", // Púrpura vibrante
        "#D4AC0D", // Dorado oscuro
        "#1ABC9C", // Verde azulado
        "#3498DB", // Azul medio
        "#E74C3C", // Rojo fuego
        "#2E86C1", // Azul medio-oscuro
        "#A93226", // Rojo oscuro
        "#F39C12", // Naranja vibrante
        "#C0392B", // Rojo granate
        "#27AE60", // Verde profundo
        "#F4D03F",],
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(',');
          return `${params.name}: ${formattedValue}`;
        }
      },
      legend: {
        show: false // Oculta la leyenda
      },
      series: [
        {
          name: 'Desembarque',
          type: 'pie',
          radius: '50%',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    return (
      <div className='container-chart-general-type-flota-resource'>
                <div className="title-text-chart-type-flota-resource">
          Desembarque por Flota
        </div>
        <ReactECharts
          className='chart-general-type-flota-resource'
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: '88%', width: '100%' }} // Ajusta el tamaño aquí
        />
      </div>
    );
  }
}

export default ChartCircleResourceFlota;
