import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FlotaDataContext } from "../../../../contexts/DataProviderFlota"; 
import "./TablePescadoresFlotaStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class TablePescadoresFlota extends Component {
  static contextType = FlotaDataContext;

  handleRowClick = (pescadorClic) => {
    /*const { categoria_pescador, setCategoriaPescador } = this.context;
    if (categoria_pescador === pescadorClic) {
      setCategoriaPescador("");
    } else {
      setCategoriaPescador(pescadorClic);
    }*/
  };

  getHeaderTitle = (region, categoria_pescador, regionName, especie) => {
    let title = "Operación de Permisos de pescadores";

    if (categoria_pescador) {
      title += ` en la Categoría ${categoria_pescador}`;
    } else {
      title += " en más de una Categoría";
    }

    if (region === "") {
      title += " País";
    } else if (region === 999) {
      title += " en el Extranjero";
    } else if (region === 99) {
      title += " en Lanchas Transportadoras";
    } else {
      title += ` en la Región ${regionName}`;
    }

    if (especie) {
      title += ` para la Especie ${especie}`;
    }

    return title;
  };

  calculateTotals(filteredFishermanData) {
    const conteoPorCategoriaPescadorYFlota = {};

    filteredFishermanData.forEach((item) => {
      const key = `${item.categoria_pescador}_${item.rpa_pescador}_${item.flota}`;
      if (!conteoPorCategoriaPescadorYFlota[key]) {
        conteoPorCategoriaPescadorYFlota[key] = {
          categoria_pescador: item.categoria_pescador,
          flota: item.flota,
        };
      }
    });

    const conteoFinalPorCategoriaPescadorYFlota = {};
    Object.values(conteoPorCategoriaPescadorYFlota).forEach((entry) => {
      const { categoria_pescador, flota } = entry;
      const key = `${categoria_pescador}_${flota}`;
      if (!conteoFinalPorCategoriaPescadorYFlota[key]) {
        conteoFinalPorCategoriaPescadorYFlota[key] = {
          conteopescadores: 0,
          flota: flota,
        };
      }
      conteoFinalPorCategoriaPescadorYFlota[key].conteopescadores += 1;
    });

    let data = Object.keys(conteoFinalPorCategoriaPescadorYFlota).map((key) => {
      const { conteopescadores, flota } =
        conteoFinalPorCategoriaPescadorYFlota[key];
      return {
        categoria_pescador: key.split("_")[0],
        flota,
        conteopescadores: conteopescadores.toLocaleString("de-DE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      };
    });

    const ordenFlotas = ["ARTESANAL", "RECOLECTOR", "INDUSTRIAL", "FABRICA"];
    const ordenArtesanal = ["ARMADOR", "BUZO"];

    data = data.sort((a, b) => {
      const flotaA = ordenFlotas.indexOf(a.flota.toUpperCase());
      const flotaB = ordenFlotas.indexOf(b.flota.toUpperCase());
      if (flotaA !== flotaB) return flotaA - flotaB;

      if (a.flota.toUpperCase() === "ARTESANAL") {
        const categoriaA = ordenArtesanal.indexOf(a.categoria_pescador.toUpperCase());
        const categoriaB = ordenArtesanal.indexOf(b.categoria_pescador.toUpperCase());
        return categoriaA - categoriaB;
      }

      return 0;
    });

    return { data };
  }

  render() {
    const { filteredFishermanData, region, categoria_pescador, especie } = this.context;
    const regionName = regionNames[region] || "Sin información disponible";

    if (!filteredFishermanData || filteredFishermanData.length === 0) {
      return (
        <div className="container-table-region-pescador">
          <TableContainer className="table-region-pescador" component={Paper}>
            <div className="header-table-region-pescador">
              <div className="header-table">
                {this.getHeaderTitle(region, categoria_pescador, regionName, especie)}
              </div>
            </div>
            <Table
              aria-label="pescador table"
              className="table-info-region-pescador"
            >
              <TableHead>
                <TableRow className="title-column">
                  <TableCell className="text-title-column" align="left">
                    Información
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1}>
                  <TableCell align="left">{"Sin pescadores"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    }

    const { data } = this.calculateTotals(filteredFishermanData);

    return (
      <TableContainer className="table-region-pescador-categoria" component={Paper}>
        <div className="header-table-region-pescador-categoria">
          <div className="header-table">
            {this.getHeaderTitle(region, categoria_pescador, regionName, especie)}
          </div>
        </div>
        <Table
          aria-label="pescador table"
          className="table-info-region-pescador-categoria"
        >
          <TableHead>
            <TableRow className="title-column">
              <TableCell className="text-title-column" align="center">
                Flota
              </TableCell>
              <TableCell className="text-title-column" align="center">
                Categoría de Pescador
              </TableCell>
              <TableCell className="text-title-column" align="center">
                Número de pescadores
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                onClick={() => this.handleRowClick(row.categoria_pescador)}
              >
                <TableCell align="center">{row.flota}</TableCell>
                <TableCell align="left">{row.categoria_pescador}</TableCell>
                <TableCell align="right">{row.conteopescadores}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default TablePescadoresFlota;
