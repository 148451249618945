import React, { useContext } from "react";
import MapRegions from "../../components/RegionScreenComponent/MapRegions/MapRegions";
import MapRegionSelect from "../../components/RegionScreenComponent/MapRegionSelect/MapRegionSelect";
import ChartCircleRegionFlota from "../../components/RegionScreenComponent/ChartsRegions/ChartCircleFlotaRegions/ChartCircleRegionFlota";
import Loading from "../../components/Loading/Loading";
import { GlobalDataContext } from "../../contexts/DataProvider";
import { RegionDataContext } from "../../contexts/DataProviderRegion";
import "./RegionScreenStyle.css";
import TableFlotaRegions from "../../components/RegionScreenComponent/Tables/TableFlotaRegions/TableFlotaRegions";
import TableFlotaRegionsCaleta from "../../components/RegionScreenComponent/Tables/TableFlotaRegionsCaleta/TableFlotaRegionsCaleta";
import TableFlotaRegionsEmbarcaciones from "../../components/RegionScreenComponent/Tables/TableFlotaEmbarcaciones/TableFlotaEmbarcaciones";
import TableFlotaRegionsEspecie from "../../components/RegionScreenComponent/Tables/TableFlotaRegionsEspecie/TableFlotaRegionsEspecie";
import TableFlotaRegionsyear from "../../components/RegionScreenComponent/Tables/TableFlotaRegionsYear/TableFlotaRegionsYear";
import ChartLineFlotaRegion from "../../components/RegionScreenComponent/ChartsRegions/ChartLineFlotaRegion/ChartLineFlotaRegion";
import ChartCircleFlotaCategoriaRegionsDesemb from "../../components/RegionScreenComponent/ChartsRegions/ChartCircleFlotaCategoriaRegionsDesemb/ChartCircleFlotaCategoriaRegionsDesemb";
import ChartCircleFlotaCategoriaRegionsEmb from "../../components/RegionScreenComponent/ChartsRegions/ChartCircleFlotaCategoriaRegionsEmb/ChartCircleFlotaCategoriaRegionsEmb";
import ChartCircleEspeciesRegions from "../../components/RegionScreenComponent/ChartsRegions/ChartCircleEspeciesRegions/ChartCircleEspeciesRegions";
import FloatingMenu from "../../components/RegionScreenComponent/FloatingMenu/FloatingMenu";
import ChartLineYearRegion from "../../components/RegionScreenComponent/ChartsRegions/ChartLineYearRegion/ChartLineYearRegion";
import ChartCircleCaletaRegions from "../../components/RegionScreenComponent/ChartsRegions/ChartCircleCaletaRegions/ChartCircleCaletaRegions";
import TablePescadoresRegion from "../../components/RegionScreenComponent/Tables/TablePescadoresRegion/TablePescadoresRegion";
import TablePescadoresCategoriaAgrupadasRegion from "../../components/RegionScreenComponent/Tables/TablePescadoresCategoriaAgrupadasRegion/TablePescadoresCategoriaAgrupadasRegion";
import ChartLinePescadoresCategorias from "../../components/RegionScreenComponent/ChartsRegions/ChartLinePescadoresCategorias/ChartLinePescadoresCategorias";
import AnalisisIA from "../../components/AnalisisIA/AnalisisIA";

export default function RegionScreen() {
  // Usar el hook useContext para acceder a DataContext
  const { isLoader } = useContext(GlobalDataContext);
  const { region } = useContext(RegionDataContext);
  return (
    <div className="container-region-select-screens">
      {isLoader ? (
        <Loading />
      ) : (
        <>
          <div className="container-region-select-0">
            {region === "" ? (
              <MapRegions />
            ) : (
              <>
                <FloatingMenu />
                <AnalisisIA/>
                <div className="container-region-info">
                  <div className="container-region-1">
                    <MapRegionSelect />
                  </div>
                  <div className="container-region-1">
                    <TableFlotaRegionsCaleta />
                    <ChartCircleCaletaRegions />
                  </div>
                  <div className="container-region-1">
                    <TablePescadoresRegion />
                    <TablePescadoresCategoriaAgrupadasRegion />
                  </div>
                  <div className="container-region-1">
                    <ChartLinePescadoresCategorias />
                  </div>
                  <div className="container-region-1">
                    
                  </div>
                  <div className="container-region-2">
                    <ChartLineYearRegion />
                    <TableFlotaRegionsyear />
                  </div>
                  <div className="container-region-3">
                    <ChartLineFlotaRegion />
                  </div>
                  <div className="container-region-4">
                    <ChartCircleRegionFlota />
                    <TableFlotaRegions />
                  </div>

                  <div className="container-region-5">
                    <TableFlotaRegionsEmbarcaciones />
                  </div>
                  <div className="container-region-6">
                    <ChartCircleFlotaCategoriaRegionsEmb />
                    <ChartCircleFlotaCategoriaRegionsDesemb />
                  </div>

                  <div className="container-region-7">
                    <ChartCircleEspeciesRegions />
                    <TableFlotaRegionsEspecie />
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
