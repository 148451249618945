import React, { Component } from "react";
import './LoadingStyle.css'
export default class Loading extends Component {
  render() {
    return (
      <div className="container-loader">
        <div className="loader"></div>
        <div className="loader_progres"></div>
        <h5 className="loader-text">Cargando</h5>
      </div>
    );
  }
}
