import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { RegionDataContext } from "../../../../contexts/DataProviderRegion";
import "./TableFlotaRegionsYearStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class TableFlotaRegionsyear extends Component {
  static contextType = RegionDataContext;

  handleRowClick = (yearClic) => {
    const { year, setYear } = this.context;
    if (year === yearClic) {
      setYear("");
    } else setYear(yearClic);
  };

  render() {
    const { filteredGeneralData, region, flota } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const regionName = regionNames[region] || "Sin información disponible";

    const desembarquePoryear = {};
    filteredGeneralData.forEach((item) => {
      const key = item.year;
      if (!desembarquePoryear[key]) {
        desembarquePoryear[key] = {
          year: item.year,
          desembarque: 0,
        };
      }
      desembarquePoryear[key].desembarque += item.desembarque;
    });

    const totalDesembarque = Object.values(desembarquePoryear).reduce(
      (sum, value) => sum + value.desembarque,
      0
    );

    const data = Object.values(desembarquePoryear).map((item) => {
      return {
        year: item.year,
        desembarque: item.desembarque,
        formattedDesembarque: item.desembarque.toLocaleString("de-DE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      };
    });

    // Ordenar por año
    data.sort((a, b) => b.year - a.year);

    return (
      <div className="container-table-region-year">
        <TableContainer className="table-region-year" component={Paper}>
          <div className="header-table-region-year">
            <div className="header-table-region-year" >
              {region === "" && flota === ""
                ? `Desembarque por año País`
                : region === "" && flota !== ""
                ? `Desembarque por año País ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}`
                : region !== "" &&
                  region !== 999 &&
                  region !== 99 &&
                  flota === ""
                ? `Desembarque por año de la Región ${regionName}`
                : region !== "" && region === 999 && flota === ""
                ? `Desembarque por año en el Extranjero`
                : region !== "" && region === 99 && flota === ""
                ? `Desembarque por año en Lanchas Transportadoras`
                : region !== "" &&
                  region !== 999 &&
                  region !== 99 &&
                  flota !== ""
                ? `Desembarque por año ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} de la Región ${regionName}`
                : region !== "" && region === 999 && flota !== ""
                ? `Desembarque por año ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en el Extranjero`
                : region !== "" && region === 99 && flota !== ""
                ? `Desembarque por año ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en Lanchas Transportadoras`
                : `Sin información disponible`}
            </div>
          </div>
          <Table aria-label="flota table" className="table-info-region-year">
            <TableHead className="no-hover-title">
              <TableRow className="title-column">
                <TableCell className="text-title-column" align="center">
                  Año
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Toneladas
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => this.handleRowClick(row.year)}
                
                >
                  <TableCell align="center">{row.year}</TableCell>
                  <TableCell align="right" className=" text-data-table">{row.formattedDesembarque}</TableCell>
                </TableRow>
              ))}
              <TableRow className="table-total-region-year no-hover">
                <TableCell align="right">
                  <strong className="table-total-text-region-year">
                    Total
                  </strong>
                </TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-region-year text-data-table"
                >
                  {totalDesembarque.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default TableFlotaRegionsyear;
