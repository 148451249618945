export const regionNames = {
    1: 'Tarapacá (I)',
    2: 'Antofagasta (II)',
    3: 'Atacama (III)',
    4: 'Coquimbo (IV)',
    5: 'Valparaíso (V)',
    6: 'Libertador Bernardo O\'Higgins (VI)',
    7: 'Maule (VII)',
    8: 'Bío-Bío (VIII)',
    9: 'La Araucanía (IX)',
    10: 'Los Lagos (X)',
    11: 'Aysén del Gral.Ibañez del Campo (XI)',
    12: 'Magallanes y Antártica Chilena (XII)',
    13: 'Metropolitana de Santiago (XIII)',
    14: 'Los Ríos (XIV)',
    15: 'Arica y Parinacota (XV)',
    16: 'Ñuble (XVI)',
    99: 'Lanchas Transportadoras',
    999: 'Extranjero'
  };