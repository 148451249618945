import React, { useContext } from "react";
import "./FloatingMenuResourceStyle.css"; // Asegúrate de enlazar el archivo CSS donde defines el estilo del menú
import { ResourceDataContext } from "../../../contexts/DataProviderResource"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const FloatingMenuResource= () => {
  // Accede al contexto usando el hook useContext
  const {
    region,
    setRegion,
    flota,
    setFlota,
    especie,
    setEspecie,
    caleta,
    setCaleta,
    year,
    setYear,
    eslora,
    setEslora,
    setIsVisibleIA
  } = useContext(ResourceDataContext);


  // Función para manejar la eliminación de filtros
  const handleDelete = (filter) => {
    switch (filter) {
      case "especie":
        setRegion("");
        setFlota("");
        setEspecie("");
        setCaleta("");
        setYear("");
        setEslora("");
        setIsVisibleIA(false); // Se oculta IA al borrar región
        break;
      case "flota":
        setFlota("");
        setIsVisibleIA(false); // Se oculta IA al borrar flota
        break;
      case "region":
        setRegion("");
        setIsVisibleIA(false); // Se oculta IA al borrar region
        break;
      case "caleta":
        setCaleta("");
        setIsVisibleIA(false); // Se oculta IA al borrar caleta
        break;
      case "year":
        setYear("");
        setIsVisibleIA(false); // Se oculta IA al borrar año
        break;
      case "eslora":
        setEslora("");
        setIsVisibleIA(false); // Se oculta IA al borrar eslora
        break;
      default:
        break;
    }
  };

  return (
    <div className="floating-menu">
      <div className="container-title-menu-floating">Filtros Aplicados</div>

      <table>
        <tbody>
        {especie !== "" && (
            <tr>
              <td>Especie: {especie}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("especie")}
                />
              </td>
            </tr>
          )}
          {region !== "" && (
            <tr>
                <td>
                Región:{" "}
                {region === 99
                  ? "Lanchas Transportadoras"
                  : region === 999
                  ? "Extranjero"
                  : region}
              </td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("region")}
                />
              </td>
            </tr>
          )}
          {flota !== "" && (
            <tr>
              <td>Flota: {flota}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("flota")}
                />
              </td>
            </tr>
          )}

          {caleta !== "" && (
            <tr>
              <td>Caleta: {caleta}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("caleta")}
                />
              </td>
            </tr>
          )}
          {year !== "" && (
            <tr>
              <td>Año: {year}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("year")}
                />
              </td>
            </tr>
          )}
          {eslora !== "" && (
            <tr>
              <td>
                Categoria: {eslora === "NO APLICA" ? "RECOLECTOR" : eslora}
              </td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete"
                  icon={faTrash}
                  onClick={() => handleDelete("eslora")}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>

    </div>
  );
};

export default FloatingMenuResource;
