import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { RegionDataContext } from "../../../../contexts/DataProviderRegion";
import { isMobile } from "react-device-detect";
import "./ChartLinePescadoresCategoriasStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class ChartLinePescadoresCategorias extends Component {
  static contextType = RegionDataContext;

  getHeaderTitle(region, flota) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "" && flota === "")
      return "Número de pescadores por Año, Categoría País";
    if (region === "" && flota !== "")
      return `Número de pescadores por Año, Categoría País ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()}`;
    if (region !== "" && flota === "")
      return `Número de pescadores por Año, Categoría en la Región ${regionName}`;
    if (region !== "" && flota !== "")
      return `Número de pescadores por Año, Categoría ${flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()} en la Región ${regionName}`;
    
    return "Sin información disponible";
  }

  render() {
    const { filteredFishermanData, region } = this.context;

    if (!filteredFishermanData || filteredFishermanData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Crear un mapa para contar el número de pescadores únicos
    const dataMap = {}; // Estructura para almacenar los datos agrupados

    // Agrupamos los datos
    filteredFishermanData.forEach((item) => {
      const year = item.year;
      const flotaCategoria = `${item.flota}-${item.categoria_pescador || "Sin categoría"}`; // Combina flota y categoría
      const rpaPescador = item.rpa_pescador; // El rpa_pescador que puede ser repetido

      // Inicializa el objeto para el año si no existe
      if (!dataMap[year]) {
        dataMap[year] = {};
      }

      // Inicializa el conjunto para contar pescadores únicos por flota-categoría
      if (!dataMap[year][flotaCategoria]) {
        dataMap[year][flotaCategoria] = new Set(); // Usamos un Set para asegurar unicidad
      }

      // Agregar el rpa_pescador al conjunto
      dataMap[year][flotaCategoria].add(rpaPescador); // Añadir el rpa_pescador al Set
    });

    const seriesData = [];
    const years = Object.keys(dataMap).sort(); // Obtener años únicos y ordenarlos
    
    // Crear series de datos para el gráfico
    years.forEach((year) => {
      Object.keys(dataMap[year]).forEach((flotaCategoria) => {
        // Obtener datos para cada año
        const dataPoints = years.map((y) => dataMap[y][flotaCategoria]?.size || 0); 
    
        // Comprobar si ya existe la serie para evitar duplicados
        if (!seriesData.find(series => series.name === flotaCategoria)) {
          seriesData.push({
            name: flotaCategoria,
            type: "line",
            data: dataPoints,
          });
        }
      });
    });
    
    
    const option = {
      color: ["#9FE080", "#5C7BD9", "#FFDC60", "#FF7F50", "#87CEEB"],
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          let tooltipText = `${params[0].name}<br/>`;
          let total = 0;

          params.forEach((param) => {
            tooltipText += `${param.marker} ${param.seriesName}: ${param.value}<br/>`;
            total += param.value;
          });

          if (total > 0) {
            tooltipText += `<strong>Total: ${total}</strong>`;
          }

          return tooltipText;
        },
      },
      legend: {
        data: [...new Set(seriesData.map(series => series.name))], // Evita nombres duplicados en la leyenda
      },
      xAxis: {
        type: "category",
        axisLabel: {
          rotate: isMobile ? 90 : 0,
        },
        data: years, // Usar los años únicos
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: value => value.toLocaleString(), // Formateo básico
        },
      },
      series: seriesData,
    };

    return (
      <div className="container-chart-line-categoria-pescador">
        <div className="title-text-chart-year">
          {this.getHeaderTitle(region, "")} {/* Cambia según tus necesidades */}
        </div>
        <ReactECharts
          className="chart-line-categoria-pescador"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          style={{ height: "87%", width: "100%", minHeight: "300px" }}
        />
      </div>
    );
  }
}

export default ChartLinePescadoresCategorias;
