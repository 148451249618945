import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FlotaDataContext } from "../../../../contexts/DataProviderFlota"; 
import "./TableFlotaFlotaEspecieStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class TableFlotaFlotaEspecie extends Component {
  static contextType = FlotaDataContext;

  handleRowClick = (especieClic) => {
    const { especie, setEspecie } = this.context;
    if (especie === especieClic) {
      setEspecie("");
    } else setEspecie(especieClic);
  };

  render() {
    const { filteredGeneralData, region, flota} = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const regionName = regionNames[region] || "Sin información disponible";

    const desembarquePorespecie = {};
    filteredGeneralData.forEach((item) => {
      const key = item.especie;
      if (!desembarquePorespecie[key]) {
        desembarquePorespecie[key] = {
          especie: item.especie,
          desembarque: 0,
        };
      }
      desembarquePorespecie[key].desembarque += item.desembarque;
    });

    const totalDesembarque = Object.values(desembarquePorespecie).reduce(
      (sum, value) => sum + value.desembarque,
      0
    );

    const data = Object.values(desembarquePorespecie).map((item) => {
      const percentage = (item.desembarque / totalDesembarque) * 100;

      // Definir el formato basado en el valor de desembarque
      const formattedDesembarque =
        item.desembarque >= 1
          ? item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          : item.desembarque < 1 && item.desembarque >= 0.1
          ? item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : item.desembarque < 0.1 && item.desembarque >= 0.01
          ? item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : item.desembarque < 0.01 && item.desembarque >= 0.001
          ? item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })
          : item.desembarque < 0.001 && item.desembarque >= 0.0001
          ? item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            })
          : item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 5,
              maximumFractionDigits: 5,
            });

      return {
        especie: item.especie,
        desembarque: item.desembarque,
        formattedDesembarque,
        percentage: isNaN(percentage)
          ? "0"
          : percentage >= 0.1
          ? percentage.toLocaleString("de-DE", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : percentage < 0.1 && percentage >= 0.01
          ? percentage.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : percentage < 0.01 && percentage >= 0.001
          ? percentage.toLocaleString("de-DE", {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })
          : percentage < 0.001 && percentage >= 0.0001
          ? percentage.toLocaleString("de-DE", {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            })
          : percentage.toLocaleString("de-DE", {
              minimumFractionDigits: 5,
              maximumFractionDigits: 5,
            }),
      };
    });

    data.sort((a, b) => b.desembarque - a.desembarque);

    return (

        <TableContainer className="table-flota-especie" component={Paper}>
          <div
            className="header-table-flota-especie"
          >
            <div
              className="header-table-flota-especie"
              
            >
              {region === "" && flota === ""
                ? `Desembarque por especie País`
                : region === "" && flota !== ""
                ? `Desembarque por especie País ${
                    flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                  }`
                : region !== "" &&
                  region !== 999 &&
                  region !== 99 &&
                  flota === ""
                ? `Desembarque por especie de la Región ${regionName}`
                : region !== "" && region === 999 && flota === ""
                ? `Desembarque por especie en el Extranjero`
                : region !== "" && region === 99 && flota === ""
                ? `Desembarque por especie en Lanchas Transportadoras`
                : region !== "" &&
                  region !== 999 &&
                  region !== 99 &&
                  flota !== ""
                ? `Desembarque por especie ${
                    flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                  } de la Región ${regionName}`
                : region !== "" && region === 999 && flota !== ""
                ? `Desembarque por especie ${
                    flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                  } en el Extranjero`
                : region !== "" && region === 99 && flota !== ""
                ? `Desembarque por especie ${
                    flota.charAt(0).toUpperCase() + flota.slice(1).toLowerCase()
                  } en Lanchas Transportadoras`
                : `Sin información disponible`}
            </div>
          </div>
          <Table aria-label="flota table" className="table-info-flota-especie">
            <TableHead className="no-hover-title">
              <TableRow className="title-column">
                <TableCell className="text-title-column" align="left">
                  Especie
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Toneladas
                </TableCell>
                <TableCell className="text-title-column" align="center">
                  Porcentaje
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => this.handleRowClick(row.especie)}
                >
                  <TableCell align="left">{row.especie}</TableCell>
                  <TableCell align="right" className="text-data-table">
                    {row.formattedDesembarque}
                  </TableCell>

                  <TableCell align="right">{row.percentage}%</TableCell>
                </TableRow>
              ))}
              <TableRow className="table-total-flota-especie no-hover">
                <TableCell align="right">
                  <strong className="table-total-text-flota-especie">
                    Total
                  </strong>
                </TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-flota-especie"
                >
                  {totalDesembarque.toLocaleString("de-DE", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
                <TableCell
                  align="right"
                  className="table-total-text-flota-especie"
                >
                  100%
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
 
    );
  }
}

export default TableFlotaFlotaEspecie;
