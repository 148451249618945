import React, { Component } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { ResourceDataContext } from "../../../contexts/DataProviderResource"; 
import { isMobile } from 'react-device-detect';
import './MapGeneralResourceStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faLocationDot } from '@fortawesome/free-solid-svg-icons'

// Función para convertir números romanos a arábigos
function romanToArabic(roman) {
  const romanToArabicMap = {
    'I': 1, 'IV': 4, 'V': 5, 'IX': 9, 'X': 10, 'XL': 40, 'L': 50, 'XC': 90,
    'C': 100, 'CD': 400, 'D': 500, 'CM': 900, 'M': 1000
  };

  let arabic = 0;
  let i = 0;

  while (i < roman.length) {
    const twoChar = roman.slice(i, i + 2);
    const oneChar = roman.slice(i, i + 1);

    if (romanToArabicMap[twoChar]) {
      arabic += romanToArabicMap[twoChar];
      i += 2;
    } else if (romanToArabicMap[oneChar]) {
      arabic += romanToArabicMap[oneChar];
      i += 1;
    }
  }

  return arabic;
}


export default class MapGeneralResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false
    };
  }

  static contextType = ResourceDataContext;

  componentDidMount() {
    const chileGeoJson = require('../../../data/regiones.json');
    echarts.registerMap('Chile', chileGeoJson);
    this.setState({ mapLoaded: true });
  }
  
  handleRegionClick = (params) => {
    const { region, setRegion } = this.context;
    const regionName = params.name;
  
    let regionNumber = '';
  
    if (regionName === 'Lanchas Transportadoras') {
      regionNumber = 99;
    } else if (regionName === 'Extranjero') {
      regionNumber = 999;
    } else {
      const match = regionName.match(/\(([^)]+)\)/);
      if (match) {
        regionNumber = romanToArabic(match[1]);
      }
    }
  
    // Actualizar el estado selectedRegion: si la región seleccionada es la misma, deselecciona
    this.setState(prevState => ({
      selectedRegion: prevState.selectedRegion === regionName ? '' : regionName
    }));
  
    if (isMobile) {
      setRegion(regionNumber);
    } else {
      if (regionNumber !== region) {
        setRegion(regionNumber);
      } else {
        setRegion('');
      }
    }
  };
  
  resetRegionClick = () => {
    const { setRegion } = this.context;
    setRegion('');
  };

  render() {
    const { mapLoaded } = this.state;
    const { filteredGeneralData, region } = this.context;

    if (!mapLoaded || !filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const allRegions = {};
    filteredGeneralData.forEach(item => {
      const key = `${item.year}_${item.flota}_${item.region_desembarque}_${item.nombre_region}`;
      allRegions[key] = {
        name: item.nombre_region,
        value: 0
      };
    });
  
    const groupedData = {};
    filteredGeneralData.forEach(item => {
      const key = `${item.year}_${item.flota}_${item.region_desembarque}_${item.nombre_region}`;
      if (!groupedData[key]) {
        groupedData[key] = {
          name: item.nombre_region,
          value: isNaN(item.desembarque) ? 0 : item.desembarque
        };
      } else {
        groupedData[key].value += isNaN(item.desembarque) ? 0 : item.desembarque;
      }
    });
  
    const mapData = Object.values({ ...allRegions, ...groupedData });

    const option = {
      visualMap: {
        show: false, // Esto oculta el visualMap
        min: 0,
        max: Math.max(...mapData.map(region => region.value)),
        inRange: {
          color: [
            '#F0F8FF',
            '#B0E0E6',
            '#ADD8E6',
            '#87CEEB',
            '#87CEFA',
            '#00BFFF',
            '#7EC8E3',
            '#6495ED',
            '#4682B4',
            '#4169E1',
            '#0000FF',
            '#0000CD',
          ]
        },
        text: ['Max', 'Min'],
        calculable: true,
        textStyle: {
          color: 'black'
        },
        formatter: function (value) {
          return value.toLocaleString('es-CL', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          });
        }
      },
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(',');
          return `${params.name}: ${formattedValue}`;
        }
      },
      series: [
        {
          name: 'DESEMBARQUE',
          type: 'map',
          roam: false,
          map: 'Chile',
          center: [-72, -36.675],
          zoom:  1.6,
          emphasis: {
            label: {
              show: true,
              color: 'green'
            },
            itemStyle: {
              areaColor: 'red',
              borderColor: 'back'
            }
          },
          data: mapData
        }
      ]
    };

    return (
      <div className='container-map-resource'>
        <h2 className='title-map'>Región de desembarque</h2>
        <ReactECharts
          className='map-resource'
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onChartReady={this.onChartReadyCallback}
          onEvents={{
            click: this.handleRegionClick,
          }}
          opts={option}
        />
      
        {/* Lanchas Transportadoras */}
        <div className={region === "Lanchas Transportadoras" ? "select-container-other-resource" : 'container-other-resource'} 
             onClick={() => this.handleRegionClick({ name: "Lanchas Transportadoras" })}>
          <FontAwesomeIcon className='logo-other-resource' icon={faLocationDot} />
          <h4 className='text-other-resource'>Lanchas Transportadoras</h4>
        </div>
    
        {/* Extranjero */}
        <div className={region === "Extranjero" ? "select-container-other-resource" : 'container-other-resource'} 
             onClick={() => this.handleRegionClick({ name: "Extranjero" })}>
          <FontAwesomeIcon className='logo-other-resource' icon={faLocationDot} />
          <h4 className='text-other-resource'>Extranjero</h4>
        </div>
      </div>
    );
    
  }
}
