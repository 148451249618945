import React, { useState, useEffect, useContext } from 'react';
import { GlobalDataContext } from './DataProvider';  // Importa el contexto, no el componente

export const ResourceDataContext = React.createContext();  // Renombra este contexto para evitar colisiones

const DataProviderResource= (props) => {
  const [region, setRegion] = useState('');
  const [caleta, setCaleta] = useState('');
  const [flota, setFlota] = useState('');   
  const [generalData, setGeneralData] = useState([]);
  const [filteredGeneralData, setFilteredGeneralData] = useState([]); 
  const [flotaData, setFlotaData] = useState([]);
  const [filteredFlotaData, setFilteredFlotalData] = useState([]); 
  const [fishermanData, setFishermanData] = useState([]);
  const [filteredFishermanData, setFilteredFishermanData] = useState([]); 
  const [especie, setEspecie] = useState(''); 
  const [year, setYear] = useState(''); 
  const [eslora, setEslora] = useState(''); 
  const [iaState, setIaState] = useState(false); 
  const [caletaTableInfo, setCaletaTableInfo] = useState([]); 
  const [caletaTableYearInfo, setCaletaTableYearInfo] = useState([]); 
  const [flotaTableYearInfo, setFlotaTableYearInfo] = useState([]); 
  const [isVisibleIA, setIsVisibleIA] = useState(false);
  const [responseIA, setResponseIA] = useState('');
  const { setIsLoader, } = useContext(GlobalDataContext);  // Usa el contexto correcto
  const [selectedLetter, setSelectedLetter] = useState('0');
  useEffect(() => {
    if  (filteredGeneralData && filteredGeneralData.length > 0) {
      // Inicializa los objetos para almacenar datos
      const desembarquePorCaletaYear = {};
      const desembarquePorFlotaYear = {};
      const desembarquePorEspecieYear = {};
      // Procesa los datos para obtener totales por caleta y año
      filteredGeneralData.forEach((item) => {
        const { caleta, year, desembarque, flota } = item;
  
        // Agregar datos por caleta
        if (!desembarquePorCaletaYear[`${caleta}-${year}`]) {
          desembarquePorCaletaYear[`${caleta}-${year}`] = { caleta, year, desembarque: 0 };
        }
        desembarquePorCaletaYear[`${caleta}-${year}`].desembarque += desembarque;
  
        // Agregar datos por flota
        if (!desembarquePorFlotaYear[`${flota}-${year}`]) {
          desembarquePorFlotaYear[`${flota}-${year}`] = { flota, year, desembarque: 0 };
        }
        desembarquePorFlotaYear[`${flota}-${year}`].desembarque += desembarque;
      });
  
      // Totales por caleta y año
      const dataYear = Object.values(desembarquePorCaletaYear).map((item) => {
        const totalDesembarqueYear = Object.values(desembarquePorCaletaYear).reduce(
          (sum, value) => (value.year === item.year ? sum + value.desembarque : sum),
          0
        );
  
        const percentage = (item.desembarque / totalDesembarqueYear) * 100;
  
        return {
          caleta: item.caleta,
          year: item.year,
          desembarque: item.desembarque,
          percentage: percentage,
        };
      });
  
      dataYear.sort((a, b) => b.desembarque - a.desembarque);
      setCaletaTableYearInfo(dataYear);
  
      // Totales por flota y año
      const dataYearFlota = Object.values(desembarquePorFlotaYear).map((item) => {
        const totalDesembarqueYear = Object.values(desembarquePorFlotaYear).reduce(
          (sum, value) => (value.year === item.year ? sum + value.desembarque : sum),
          0
        );
  
        const percentage = (item.desembarque / totalDesembarqueYear) * 100;
  
        return {
          flota: item.flota,
          year: item.year,
          desembarque: item.desembarque,
          percentage: percentage,
        };
      });
  
      dataYearFlota.sort((a, b) => b.desembarque - a.desembarque);
      setFlotaTableYearInfo(dataYearFlota);

            // Totales por especie y año
// Procesar los datos filtrados para especies y años
filteredGeneralData.forEach((item) => {
  const { especie, year, desembarque } = item;

  // Agregar datos por especie
  if (!desembarquePorEspecieYear[`${especie}-${year}`]) {
    desembarquePorEspecieYear[`${especie}-${year}`] = { especie, year, desembarque: 0 };
  }
  desembarquePorEspecieYear[`${especie}-${year}`].desembarque += desembarque;
});

// Totales por especie y año
const dataYearEspecie = Object.values(desembarquePorEspecieYear).map((item) => {
  const totalDesembarqueYear = Object.values(desembarquePorEspecieYear).reduce(
    (sum, value) => (value.year === item.year ? sum + value.desembarque : sum),
    0
  );

  // Calcular el porcentaje de desembarque por especie en ese año
  const percentage = (item.desembarque / totalDesembarqueYear) * 100;

  return {
    especie: item.especie,
    year: item.year,
    desembarque: item.desembarque,
    percentage: percentage,
  };
});

// Ordenar los datos por desembarque
dataYearEspecie.sort((a, b) => b.desembarque - a.desembarque);



            
    }
    
  }, [filteredGeneralData]);
  


// Contexto
useEffect(() => {
  if (especie !== '') {
    setIsLoader(true); // Activa el estado de carga
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/recursos?resource=${especie}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        const { resourceCapturesData, resourceBoatsData, resourcefishermanData } = jsonData;
        console.log(jsonData)
        setGeneralData(resourceCapturesData);
        setFilteredGeneralData(resourceCapturesData);
        setFlotaData(resourceBoatsData);
        setFilteredFlotalData(resourceBoatsData);
        setFishermanData(resourcefishermanData);
        setFilteredFishermanData(resourcefishermanData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoader(false); // Desactiva el estado de carga al terminar
     
      }
    };
    fetchData();
  }
}, [especie, setIsLoader]);


  // Llamada a la API de OpenAI al cambiar `iaState` o `caletaInfo`
  useEffect(() => {
    const fetchIAResponse = async () => {
      if (!iaState) return; // Salir si `iaState` es falso
   
      try {

        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [
              { role: "system", content: "Eres un estadístico especializado en el análisis de datos pesqueros." },
              {
                role: "user",
                content: ` ${flota ? `perteneciente a la flota ${flota}, ` : ''}${especie? `analizaremos la especie ${especie}. ` : ''} A continuación, se presentan los datos por año para cada caleta: ${JSON.stringify(caletaTableYearInfo)}. Además, estos son los datos anuales por flota: ${JSON.stringify(flotaTableYearInfo)}. Por favor, realiza un análisis integrador que considere los datos de las caletas, flotas. Es fundamental que tu análisis inicie mencionando la región y la flota , si corresponde, e identifique comportamientos clave, hitos importantes y patrones en los datos de desembarque. Incluye observaciones relevantes sobre las caletas y las flotas en los datos proporcionados.`
              }
            ],
            temperature: 0.4,
            max_tokens: 1500,
          }),
          
        });
  
        if (!response.ok) {
          throw new Error('Error al consultar la API de OpenAI');
        }
  
        const jsonData = await response.json();
        const responseMessage = jsonData.choices[0].message.content;
        setResponseIA(`Analisis de SmartFish: ${responseMessage}`);

        // Aquí podrías hacer algo con el `responseMessage`, por ejemplo, guardarlo en un estado
      } catch (error) {
        console.error('Error en la consulta a la IA:', error);
      } finally {
        setIaState(false); // Asegúrate de resetear `iaState` al final
      }
    };
  
    fetchIAResponse();
  }, [iaState, caletaTableInfo, caletaTableYearInfo, region, especie, flota,flotaTableYearInfo]);

  // Aplicación de filtros
  useEffect(() => {
    if (especie !== '') {

      const applyFilters = () => {
        let filteredData = Array.isArray(generalData) ? [...generalData] : [];
        let filteredEmbarcaciones = Array.isArray(flotaData) ? [...flotaData] : [];
        let filteredPescadores= Array.isArray(fishermanData) ? [...fishermanData] : [];    
       
        if (flota !== '') {
          filteredData = filteredData.filter(item => item.flota === flota);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.flota === flota);
          filteredPescadores = filteredPescadores.filter(item => item.flota === flota);
        }
       
        if (caleta !== '') {
          filteredData = filteredData.filter(item => item.caleta === caleta);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.puerto_caleta === caleta);
          filteredPescadores = filteredPescadores.filter(item => item.puerto_caleta === caleta);
        }
        if (region !== '') {
          filteredData = filteredData.filter(item => item.region_desembarque === region);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.region_desembarque === region);
          filteredPescadores = filteredPescadores.filter(item => item.region_desembarque === region);
        }
        if (year !== '') {
          filteredData = filteredData.filter(item => item.year === year);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.year === year);
          filteredPescadores = filteredPescadores.filter(item => item.year === year);
        }
        if (eslora !== '') {
          filteredData = filteredData.filter(item => item.categoria_eslora === eslora);
          filteredEmbarcaciones = filteredEmbarcaciones.filter(item => item.categoria_eslora=== eslora);
          filteredPescadores = filteredPescadores.filter(item => item.categoria_eslora === eslora);
        }
        if (filteredData.length === 0) {
          setFilteredGeneralData([
            {
              "caleta":caleta,
              "categoria_eslora": eslora,
              "desembarque": 0.0000001,
              "especie":especie,
              "flota":flota,
              "nombre_region": region,
              "region_desembarque": region,
              "year": 0
  
            }
          ]);
        } else {
          setFilteredGeneralData(filteredData);
        }

        if (filteredEmbarcaciones.length === 0) {
          setFilteredFlotalData([]);
        } else {
          setFilteredFlotalData(filteredEmbarcaciones);
        }

               if (filteredPescadores.length === 0) {
                setFilteredFishermanData([]);
        } else {
          setFilteredFishermanData(filteredPescadores);
        }
       



      };



      applyFilters();
    }
  }, [region, flota, caleta, especie, year, eslora, generalData, flotaData, fishermanData]);

  return (
    <ResourceDataContext.Provider value={{
      region, setRegion,
      caleta, setCaleta,
      flota, setFlota,
      generalData, filteredGeneralData,
      flotaData, filteredFlotaData,
      fishermanData, filteredFishermanData,
      especie, setEspecie,
      year, setYear,
      eslora, setEslora,
      iaState, setIaState,
      caletaTableInfo, 
      setCaletaTableInfo,
      isVisibleIA, 
      setIsVisibleIA,
      responseIA, 
      setResponseIA,
      selectedLetter, 
      setSelectedLetter
    }}>
      {props.children}
    </ResourceDataContext.Provider>
  );
};

export default DataProviderResource;
