import React, { Component } from 'react';
import ReactECharts from 'echarts-for-react';
import { HomeDataContext } from '../../../../contexts/DataProviderHome';
import { isMobile } from 'react-device-detect';
import './ChartLineFlota.css';

export class ChartLineFlota extends Component {
  static contextType = HomeDataContext;

  handleFlotaChange = (selectedFlota) => {
    // Actualiza la flota en el contexto
    if (this.context.flota===selectedFlota) {
      this.context.setFlota('')
    }else 
    this.context.setFlota(selectedFlota);
  };

  render() {
    const { filteredGeneralData } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    const flotas = {};
    filteredGeneralData.forEach(item => {
      if (!flotas[item.flota]) {
        flotas[item.flota] = {};
      }
      if (!flotas[item.flota][item.year]) {
        flotas[item.flota][item.year] = 0;
      }
      flotas[item.flota][item.year] += item.desembarque;
    });

    const seriesData = Object.keys(flotas).map(flota => ({
      name: flota,
      type: 'line',
      data: Object.keys(flotas[flota]).map(year => flotas[flota][year]),
    }));

    const formatChileanNumber = value => {
      if (isNaN(value)) return 0;
      const roundedValue = Math.round(value * 10) / 10;
      const parts = roundedValue.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts.join(',');
    };

    const option = {
      color: ['#9FE080', '#5C7BD9', '#FFDC60'],
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          const value = params[0]?.value || 0;
          return `${params[0].name}: ${formatChileanNumber(value)}`;
        }
      },
      legend: {
        data: Object.keys(flotas),
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          rotate: isMobile ? 90 : 0
        },
        data: Object.keys(flotas[Object.keys(flotas)[0]]), 
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: formatChileanNumber
        }
      },
      series: seriesData,
    };

    return (
      <div className='container-chart-general-line'>
        <ReactECharts
          className='chart-general'
          option={option}
          notMerge={true}
          lazyUpdate={false}
          style={{ height: '100%', width: '100%', minHeight: '300px' }}
          onEvents={{
            legendselectchanged: (params) => {
              this.handleFlotaChange(params.name); // Actualiza la flota seleccionada
            }
          }}
        />
      </div>
    );
  }
}

export default ChartLineFlota;
