import React, { useContext } from "react";
import logo from "../../assets/img/FISH2.png";
import { GlobalDataContext } from "../../contexts/DataProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./NavbarMainStyle.css";

const NavbarMain = () => {
  const { showDrawer, setShowDrawer, showLogin, setShowLogin } =
    useContext(GlobalDataContext);
  const navigate = useNavigate();
  const goTo = () => {
    navigate(`/`);
  };

  const handleClick = () => {
    setShowDrawer(!showDrawer); // Cambia el estado showDrawer al valor opuesto
    setShowLogin(false);
  };

  const handleLoginClick = () => {
    setShowLogin(!showLogin); // Cambia el estado showDrawer al valor opuesto
  };

  return (
    <div className="header-container">
      <div>
        <div className="header-container-burger" onClick={handleClick}>
          <FontAwesomeIcon icon={faBars} />

          <h1 className="header-burger-text">Buscar información de pesca...</h1>
        </div>
        <div className="header-container-welcome" onClick={() => goTo()}>
          <h2 className="header-welcome-text">Bienvenidos a </h2>
          <img
            className="header-welcome-logo"
            src={logo}
            alt="Logo de empresa SmartFish"
          />
          <h1 className="header-welcome-text">SmartFish</h1>
        </div>
      </div>
      <div className="header-container-login" onClick={handleLoginClick}>
        <FontAwesomeIcon icon={faUser} />

        <h3 className="header-login-text">Login</h3>
      </div>
    </div>
  );
};

export default NavbarMain;
