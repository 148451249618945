import { jwtDecode } from 'jwt-decode'; // Importación nombrada

/** @description Retorna un objeto con la información del token */
export const getTokenData = () => {
  try {
    const token = sessionStorage.getItem('token'); // Cambia localStorage por sessionStorage
    if (!token) {
      return null; // Cambiado para devolver null si no hay token
    } 
    const tokenData = jwtDecode(token); // Decodificar el token
    return tokenData; // Retorna los datos decodificados
  } catch (e) {
    console.error('Error decoding token:', e.message);
    return null;
  }
};