
import DataProvider from './contexts/DataProvider'
import { AppRouter } from './routers/AppRouter';
function App() {
  return (
    <div id="root">
      <DataProvider>
        <AppRouter />  
      </DataProvider>
     
    </div>
  );
}

export default App;
