import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import "./MapRegionSelectStyle.css";
import { RegionDataContext } from "../../../contexts/DataProviderRegion"; // Importa el contexto
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faMapLocationDot,
  faShip,
} from "@fortawesome/free-solid-svg-icons";
import { regionNames } from "../../../helpers/RegionHelpers";
export default class MapRegionSelect extends Component {
  // Define el contexto que se va a usar
  static contextType = RegionDataContext;

  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,
      regions: [],
      currentRegion: "", // Almacena la región cargada actualmente
    };
  }

  // Función que se ejecuta al hacer clic en el botón de "Seleccionar otra región"
  handleRegionClick = () => {
    const {
      setRegion,
      setFlota,
      setEspecie,
      setCaleta,
      setYear,
      setEslora,
    } = this.context;

    if (setRegion) {
      setRegion(""); // Restablecer la región a una cadena vacía
    }
    if (setFlota) {
      setFlota(""); // Restablecer la región a una cadena vacía
    }
    if (setCaleta) {
      setCaleta(""); // Restablecer la región a una cadena vacía
    }
    if ( setEspecie) {
       setEspecie(""); // Restablecer la región a una cadena vacía
    }
    if (setYear) {
      setYear(""); // Restablecer la región a una cadena vacía
    }
    if (setEslora) {
      setEslora(""); // Restablecer la región a una cadena vacía
    }
  };

  // Función para cargar el archivo JSON dinámicamente según la región
  async loadMapForRegion(region) {
    try {
      const mapData = await import(`../../../data/${region}.json`);
      echarts.registerMap("Chile", mapData.default); // Registra el mapa en echarts
      this.setState({
        mapLoaded: true,
        regions: this.getRegions(),
        currentRegion: region,
      });
    } catch (error) {
      console.error("Error al cargar el archivo JSON:", error);
    }
  }

  componentDidMount() {
    const { region } = this.context;
    if (region !== 99 || region !== 999) {
      this.loadMapForRegion(region || 15);
    }
    // Carga la región por defecto si no hay región en el contexto
  }

  componentDidUpdate(prevProps, prevState) {
    const { region } = this.context;

    // Solo recarga el mapa si la región ha cambiado y no es una cadena vacía
    if (prevState.currentRegion !== region && region !== "") {
      // Verifica si el estado ya está en el proceso de carga
      if (!this.state.mapLoaded) {
        this.loadMapForRegion(region); // Carga el nuevo mapa
      }
    }
  }

  getRegions() {
    return [];
  }

  render() {
    const { mapLoaded, regions } = this.state;
    const { region } = this.context;
    const regionName = regionNames[region] || "Sin información disponible";
    if (!mapLoaded && region !== "") {
      return (
        <div className="container-map-region-select">
          <h2 className="title-map">{regionName}</h2>
          <div
            className={"select-container-other-region-select"}
            onClick={this.handleRegionClick}
          >
            <FontAwesomeIcon
              className="logo-other-region-select"
              icon={faBackward}
            />
            <h4 className="text-other-region-select">
              Seleccionar otra región
            </h4>
          </div>
          <div className="container-no-map">
            {region === 99 ? (
              <FontAwesomeIcon className="logo-no-map" icon={faShip} />
            ) : region === 999 ? (
              <FontAwesomeIcon
                className="logo-no-map"
                icon={faMapLocationDot}
              />
            ) : (
              <></>
              
            )}
          </div>
        </div>
      );
    }

    const mapData = regions.map((region) => ({
      name: region,
    }));

    const option = {
      visualMap: {
        show: false,
        min: 0,
        max: 1,
        inRange: {
          color: [
            "#F0F8FF",
            "#B0E0E6",
            "#ADD8E6",
            "#87CEEB",
            "#87CEFA",
            "#00BFFF",
            "#7EC8E3",
            "#6495ED",
            "#4682B4",
            "#4169E1",
            "#0000FF",
            "#0000CD",
          ],
        },
        text: ["Max", "Min"],
        calculable: true,
        textStyle: {
          color: "black",
        },
      },
      tooltip: {
        trigger: "item",
        showDelay: 0,
        transitionDuration: 0.2,
        formatter: function (params) {
          return `${params.name}`;
        },
      },
      series: [
        {
          name: "Regiones",
          type: "map",
          roam: false, // Desactivar el zoom y el desplazamiento
          map: "Chile",
          zoom: 0.8,
          itemStyle: {
            areaColor: "blue", // Color constante para el interior del polígono
            borderColor: "black",
          },
          emphasis: {
            label: {
              show: true,
              color: "green",
            },
            itemStyle: {
              areaColor: "blue", // Color constante para el interior del polígono al hacer hover
              borderColor: "black",
            },
          },
          data: mapData,
        },
      ],
    };

    return (
      <div className="container-map-region-select">
        <h2 className="title-map">{regionName}</h2>
        <div
          className={"select-container-other-region-select"}
          onClick={this.handleRegionClick}
        >
          <FontAwesomeIcon
            className="logo-other-region-select"
            icon={faBackward}
          />
          <h4 className="text-other-region-select">Seleccionar otra región</h4>
        </div>
        <ReactECharts
          className="map-region-select"
          option={option}
          notMerge={true}
          lazyUpdate={false}
        />
      </div>
    );
  }
}
