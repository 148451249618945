import React, { useContext} from "react";
import "./FloatingMenuHomeStyle.css";
import { HomeDataContext } from '../../../contexts/DataProviderHome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const FloatingMenuHome = () => {
  const { region, setRegion, flota, setFlota } = useContext(HomeDataContext);

  // Maneja la eliminación de los filtros
  const handleDelete = (filter) => {
    switch (filter) {
      case "region":
        setRegion("");
        break;
      case "flota":
        setFlota("");
        break;
      default:
        console.warn(`Filtro desconocido: ${filter}`);
    }
  };



  return (
    <div className={`floating-menu-home ${
region===''	&	flota===''	?	'filter-heig-0'	:
region===''	&	flota !==''	?	'filter-heig-1'	:
region!==''	&	flota===''	?	'filter-heig-1'	:
region!==''	&	flota !==''	?	'filter-heig-2': 
'filter-heig-none'	
    }`}>
      <div className="container-title-menu-floating-home">Filtros Aplicados</div>
      <table>
        <tbody>
          {region !== "" && (
            <tr>
              <td>Región: {region}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete-home"
                  icon={faTrash}
                  onClick={() => handleDelete("region")}
                />
              </td>
            </tr>
          )}
          {flota !== "" && (
            <tr>
              <td>Flota: {flota}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-floating-menu-delete-home"
                  icon={faTrash}
                  onClick={() => handleDelete("flota")}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FloatingMenuHome;
