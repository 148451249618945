import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { FlotaDataContext } from "../../../../contexts/DataProviderFlota";
import "./TableFlotaFlotaRegionStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class TableFlotaFlotaRegion extends Component {
  static contextType = FlotaDataContext;

  handleRowClick = (regionCode) => {
    const { region, setRegion } = this.context;
    if (region === regionCode) {
      setRegion("");
    } else setRegion(regionCode);
  };

  render() {
    const { filteredGeneralData, region, flota } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Agrupamos los datos por región y sumamos el desembarque para cada región
    const desembarquePorRegion = {};
    filteredGeneralData.forEach((item) => {
      const regionCode = item.region_desembarque;
      if (!desembarquePorRegion[regionCode]) {
        desembarquePorRegion[regionCode] = {
          region: regionNames[regionCode] || "Sin información",
          desembarque: 0,
        };
      }
      desembarquePorRegion[regionCode].desembarque += item.desembarque;
    });

    const totalDesembarque = Object.values(desembarquePorRegion).reduce(
      (sum, value) => sum + value.desembarque,
      0
    );

    // Preparar los datos para la tabla
    const data = Object.keys(desembarquePorRegion).map((regionCode) => {
      const item = desembarquePorRegion[regionCode];
      const percentage = (item.desembarque / totalDesembarque) * 100;

      const formattedDesembarque =
        item.desembarque >= 1
          ? item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
          : item.desembarque < 1 && item.desembarque >= 0.1
          ? item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })
          : item.desembarque < 0.1 && item.desembarque >= 0.01
          ? item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : item.desembarque.toLocaleString("de-DE", {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            });

      return {
        regionCode: parseInt(regionCode), // Usar el código de la región para la identificación
        regionName: item.region,
        desembarque: item.desembarque,
        formattedDesembarque,
        percentage: isNaN(percentage) ? "0" : percentage.toLocaleString("de-DE", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }),
      };
    });

    data.sort((a, b) => b.desembarque - a.desembarque);

    return (
      <TableContainer className="table-region-especie" component={Paper}>
        <div className="header-table-region-especie">
          <div className="header-table-region-especie">
            {region === "" && flota === ""
              ? `Desembarque por región País`
              : `Desembarque por región de la flota ${flota}`}
          </div>
        </div>
        <Table aria-label="flota table" className="table-info-region-especie">
          <TableHead className="no-hover-title">
            <TableRow className="title-column">
              <TableCell className="text-title-column" align="left">
                Región
              </TableCell>
              <TableCell className="text-title-column" align="center">
                Toneladas
              </TableCell>
              <TableCell className="text-title-column" align="center">
                Porcentaje
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                onClick={() => this.handleRowClick(row.regionCode)} // Enviar el código de la región al hacer clic
              >
                <TableCell align="left">{row.regionName}</TableCell>
                <TableCell align="right" className="text-data-table">
                  {row.formattedDesembarque}
                </TableCell>
                <TableCell align="right">{row.percentage}%</TableCell>
              </TableRow>
            ))}
            <TableRow className="table-total-region-especie no-hover">
              <TableCell align="right">
                <strong className="table-total-text-region-especie">
                  Total
                </strong>
              </TableCell>
              <TableCell
                align="right"
                className="table-total-text-region-especie"
              >
                {totalDesembarque.toLocaleString("de-DE", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </TableCell>
              <TableCell
                align="right"
                className="table-total-text-region-especie"
              >
                100%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default TableFlotaFlotaRegion;
