import React, { useContext } from 'react';
import './FlotaBarStyle.css';
import { FlotaDataContext } from '../../../contexts/DataProviderFlota';

const FlotaBar = () => {
  const flotas = ['ARTESANAL', 'FABRICA', 'RECOLECTOR', 'INDUSTRIAL'];
  const { setFlota } = useContext(FlotaDataContext); 

  const handleFlotaClick = (flota) => {
    setFlota(flota); 
  };

  return (
    <div className="flota-bar">
      {flotas.map((flota) => (
        <button 
          key={flota} 
          className="flota-button" 
          onClick={() => handleFlotaClick(flota)} 
        >
          {flota}
        </button>
      ))}
    </div>
  );
};

export default FlotaBar;
