import React, { Component } from "react";
import ReactECharts from "echarts-for-react";
import { FlotaDataContext } from "../../../../contexts/DataProviderFlota"; 
import "./ChartCircleRegionFlotaStyle.css";
import { regionNames } from "../../../../helpers/RegionHelpers";

export class ChartCircleRegionFlota extends Component {
  static contextType = FlotaDataContext;

  getHeaderTitle(region, eslora) {
    const regionName = regionNames[region] || "Sin información disponible";

    if (region === "" && eslora === "")
      return "Desembarque por Región País";
    if (region === "" && eslora !== "" )
      return `Desembarque por Región País ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      }`;

    if (region !== "" && region !== 999 && region !== 99 && eslora === "")
      return `Desembarque por Región en la Región ${regionName}`;
    if (region !== "" && region === 999 && eslora === "")
      return "Desembarque por Región en el Extranjero";
    if (region !== "" && region === 99 && eslora === "")
      return "Desembarque por Región en Lanchas Transportadoras";

    if (region !== "" && region !== 999 && region !== 99 && eslora !== "" )
      return `Desembarque por Región ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en la Región ${regionName}`;

    if (region !== "" && region === 999 && eslora !== "")
      return `Desembarque por Región ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en el Extranjero`;

    if (region !== "" && region === 99 && eslora !== "")
      return `Desembarque por Región ${
        eslora.charAt(0).toUpperCase() + eslora.slice(1).toLowerCase()
      } en Lanchas Transportadoras`;

    return "Sin información disponible";
  }

  handleChartClick = (params) => {
    const { setRegion, region } = this.context;
    
    if (params.data.regionCode !== region) {
      setRegion(params.data.regionCode);  // Usar el código de la región
    } else {
      setRegion("");
    }
  };

  render() {
    const { filteredGeneralData, region, flota, eslora } = this.context;

    if (!filteredGeneralData || filteredGeneralData.length === 0) {
      return <div>No hay datos disponibles.</div>;
    }

    // Inicializar un objeto para almacenar el desembarque por región
    const desembarquePorregion = {};

    // Calcular el total de desembarque para cada región
    filteredGeneralData.forEach((item) => {
      const regionCode = item.region_desembarque;
      if (!desembarquePorregion[regionCode]) {
        desembarquePorregion[regionCode] = 0;
      }
      desembarquePorregion[regionCode] += item.desembarque;
    });

    // Preparar datos para el gráfico, utilizando los nombres de región y ordenados de mayor a menor
    const data = Object.keys(desembarquePorregion)
      .map((regionCode) => ({
        name: regionNames[regionCode] || "Sin información",
        value: desembarquePorregion[regionCode],
        regionCode: parseInt(regionCode),  // Guardar el código de la región aquí
      }))
      .sort((a, b) => b.value - a.value); // Ordenar de mayor a menor

    const option = {
      color: [
        "#E74C3C", 
        "#3498DB", 
        "#2ECC71", 
        "#F1C40F", 
        "#9B59B6", 
        "#1ABC9C", 
        "#FF5733", 
        "#2980B9", 
        "#D35400", 
        "#8E44AD", 
        "#C0392B", 
        "#27AE60", 
        "#F39C12", 
        "#16A085", 
        "#2C3E50", 
        "#F9E79F"
      ],
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          let value = params.value;
          if (isNaN(value)) {
            value = 0;
          }
          const roundedValue = Math.round(value * 10) / 10;
          const parts = roundedValue.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          const formattedValue = parts.join(",");
          return `${params.name}: ${formattedValue}`;
        },
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: "Desembarque",
          type: "pie",
          radius: "50%",
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    return (
      <div className="container-chart-general-resource-region">
        <div className="title-text-chart-region-resource">
          {this.getHeaderTitle(region, flota, eslora)}
        </div>

        <ReactECharts
          className="chart-general-resource-region"
          option={option}
          notMerge={true}
          lazyUpdate={false}
          onEvents={{ click: this.handleChartClick }}
          style={{ height: "85%", width: "100%" }}
        />
      </div>
    );
  }
}

export default ChartCircleRegionFlota;
